import Colleges from "../components/Colleges";
import Documents from "../components/Documents";

const addDocsToPages = async (user, pages) => {
  const requiredDocuments = [
    "lease",
    "transcript",
    "sat",
    "fasfa",
    "essay",
    "recommendation1",
    "recommendation2",
  ];

  const fieldNames = [
    "Lease",
    "High School Transcript",
    "SAT",
    "FASFA",
    "Essay",
    "First Recomendation Letter",
    "Second Recomendation Letter",
  ];

  return [
    ...pages,
    {
      pageName: "Colleges",
      questions:
        user.colleges?.length > 0
          ? user.colleges.map((qs, i) => {
              return {
                _id: "college" + i,
                key: "college" + i + "key",
                question: qs.name,
                required: qs.accepted,
                answer: qs?.letter?.fileName ?? "",
                type: "college",
              };
            })
          : [
              {
                _id: "college0",
                question: "No colleges listed",
                type: "college",
                answer: "",
              },
            ],
      special: true,
      component: <Colleges />,
    },
    {
      pageName: "Required Documents",
      questions: requiredDocuments.map((question, i) => {
        return {
          _id: "document" + i,
          key: "doc" + i + "key",
          question: fieldNames[i],
          required: true,
          answer: user[question]?.fileName ?? "",
          type: "document",
        };
      }),
      special: true,
      component: <Documents />,
    },
  ];
};

export default addDocsToPages;
