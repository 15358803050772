import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import globalContext from "../contexts/globalContext";
import parseErrors from "../helpers/parseErrors";
import axios from "axios";
import getProgress from "../helpers/getProgress";
import addDocsToPages from "../helpers/addDocsToPages";
import Loading from "../components/Loading";

import "../style/pFriendly.css";

const PrinterFriendly = ({ location }) => {
  const history = useHistory();
  const { user, addMessage, printUser, setPrintUser } = useContext(
    globalContext
  );
  const [pages, setPages] = useState(null);

  let params = new URLSearchParams(location.search);

  let getUserEmail = params.get("email");

  const getUser = axios
    .post("/getSingleUser", {
      email: localStorage.getItem("email"),
      getUserEmail,
    })
    .then((response) => {
      return response.data;
    });

  const getPages = axios
    .post("/getPages", {
      email: localStorage.getItem("email"),
    })
    .then((response) => {
      return response.data;
    });

  const getUserInfo = async () => {
    Promise.all([getPages, getUser])
      .then((answers) => {
        addDocsToPages(answers[1], answers[0])
          .then((newPages) => getProgress(newPages, answers[1].answers))
          .then((response) => setPages(response.finalAnswers));
      })
      .catch((err) => parseErrors(err).then((msg) => addMessage(msg)));
  };

  const SingleQuestion = ({ qa, i }) => {
    let { question, answer, type } = qa;
    if (!question && qa[0]) {
      question = qa[0].question;
      answer = qa[0].answer;
    }

    if (type === "document")
      answer = answer ? "Document Uploaded" : "Missing Document";
    if (type === "college")
      answer = answer
        ? "Acceptance Letter Uploaded"
        : "Missing Acceptance Letter";
    console.log(question);
    return (
      <Col sm={12} key={"qa" + i}>
        <div style={{ margin: ".15rem 0" }}>
          <span style={{ fontWeight: "bold" }}>{`${question}:`}</span>
          <span
            style={{
              color: !answer || answer.startsWith("Missing") ? "red" : "green",
              marginLeft: "1rem",
            }}
          >
            {answer?.length > 0 ? answer : "blank"}
          </span>
        </div>
      </Col>
    );
  };

  const SinglePageOfQuestions = ({ page }) => {
    if (!page) return <></>;
    if (page.length > 1) {
      return page.map((qa, i) => (
        <SingleQuestion pageId={page._id} i={i} qa={qa} />
      ));
    } else return <SingleQuestion pageId={Date.now()} i={0} qa={page} />;
  };

  useEffect(() => {
    if (!printUser) setPrintUser(getUserEmail);
    if (window && user && pages) {
      setTimeout(() => {
        window.focus();
        window.print();
      }, 500);
    }
  }, [user, pages]);

  useEffect(() => {
    getUserInfo();
  }, []);

  if (!user || !pages) return <Loading />;

  return (
    <Container
      id="pfriendly"
      className="mainContent"
      style={{
        backgroundColor: "rgba(255,255,255, 1)",
        height: "auto",
        padding: "1rem",
        justifyContent: "flex-start",
        margin: "auto",
        overflowX: "hidden",
      }}
    >
      <Row style={{ float: "left", borderBottom: "1px solid" }}>
        <Col className="pFriendlyHeader" sm={12}>
          <Image
            style={{ height: "5rem", margin: "1rem" }}
            src="nhaMedium.png"
            roundedCircle
          />

          <h4>NHASF Application</h4>

          <Image
            style={{ height: "5rem", float: "right", margin: "1rem" }}
            src="nhasf.png"
            roundedCircle
          />
        </Col>
      </Row>

      <hr></hr>
      <Row>
        {pages.map((page, i) => (
          <SinglePageOfQuestions key={"pg" + i} page={page} />
        ))}
      </Row>
    </Container>
  );
};

export default PrinterFriendly;
