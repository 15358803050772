import makeId from "./makeId";

const handleAddMessage = async (message, type, messages, setMessages) => {
  let msgId = await makeId(5);
  //create temporary msg variable so we can parse the message and type of the message
  let msg = { msg: message, id: msgId, dateCreated: Date.now(), type };
  // //if message is a string then take the message and type strings and make an object
  // if (typeof message === "string") {
  //   msg = { msg: message, type };
  // } else {
  //   //message must be an object
  //   msg = message;
  //   //in case I sent it as an object and use message instead of msg
  //   if (msg.message) msg.msg = msg.message;
  // }

  // msg.id = msgId;
  // msg.dateCreated = Date.now();
  setMessages([...messages, msg]);
};

export default handleAddMessage;
