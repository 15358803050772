import Spinner from "react-bootstrap/Spinner";

const Loading = ({ caption, fullHeight }) => {
  return (
    <div
      className="loading"
      style={{
        animation: "fadeIn 1s",
        height: fullHeight ? "90vh" : "initial",
      }}
    >
      <Spinner
        style={{ color: "rgba(var(--primColor), 1)" }}
        animation="grow"
      />
      {caption ?? <>Loading...</>}
    </div>
  );
};

export default Loading;
