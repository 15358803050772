import "../node_modules/normalize.css/normalize.css";
import "./sass/main.css";
import "./style/app.css";
import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ToastHolder from "./components/ToastHolder";
import Navigation from "./components/Navigation";
import Home from "./pages/Home";
import Application from "./pages/Application";
import Apanel from "./pages/Apanel";
import Dashboard from "./pages/Dashboard";
import Footer from "./components/Footer";
import Row from "react-bootstrap/Row";
import updateToken from "./helpers/updateToken";
import axios from "axios";
import parseErrors from "./helpers/parseErrors";
import handleAddMessage from "./helpers/handleAddMessage";
import removeMessage from "./helpers/removeMessage";
import globalContext from "./contexts/globalContext";
import VBackground from "./components/Vbackground";
import Loading from "./components/Loading";
import timeKeeper from "./helpers/timeKeeper";
import RegisterComplete from "./pages/RegisterComplete";
import PrinterFriendly from "./pages/PrinterFriendly";
import PageNotFound from "./pages/PageNotFound";
import Dialog from "./components/Dialog";

let upTime = new timeKeeper();
var baseURL;
const appMode = process?.env?.REACT_APP_MODE ?? "production";

axios.defaults.headers.common = {
  Authorization: `bearer ${localStorage.getItem("jwt") ?? null}`,
};

if (!process?.env?.REACT_APP_BASE_URL?.startsWith("http://localhost"))
  console.log = () => {};

axios.interceptors.request.use(
  function (config) {
    // let baseURL = "https://demo.newarkha.org/api";
    baseURL =
      process?.env?.REACT_APP_BASE_URL ?? "https://nhasf.newarkha.org/api";
    // Update token before most requests
    let email = localStorage.getItem("email");
    if (!config || !config.url) return;
    if (!config.url.startsWith("http")) config.url = baseURL + config.url;

    if (
      !config.url.includes("/login") &&
      !config.url.includes("/register") &&
      !config.url.includes("/updateToken") &&
      config.method === "post"
    ) {
      //System to prevent updateToken from triggering if the token has been updated within
      //the last 5 seconds
      //console.log(upTime.timeLastUpdated, upTime.timeLimit);

      if (upTime.timeLastUpdated > upTime.timeLimit) {
        //console.log("restart timer");
        updateToken(email);
        upTime.restartTimer();
      }
    }

    return config;
  },

  function (error) {
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
  }
);

function App() {
  let [messages, setMessages] = useState([]);
  let [user, setUser] = useState(null);
  const [name, setName] = useState(null);
  let [location, setLocation] = useState("");
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [mobile, setMobile] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [deviceSize, setDeviceSize] = useState("md");
  const [printUser, setPrintUser] = useState(null);
  const [users, setUsers] = useState([]);

  const [loginVisible, setLoginVisible] = useState(false);
  const [regVisible, setRegVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);

  const [bkgSettings, setBkgSettings] = useState({
    playInterval: 30000,
    selected: 0,
    shuffle: true,
    defaultPlayInterval: 30000,
  });

  const [dialog, setDialogOption] = useState({ show: false });

  const setDialog = (valuePair) => {
    if (!valuePair?.show) {
      setDialogOption({ show: false });
      return;
    }
    setDialogOption({ ...dialog, ...valuePair });
  };

  const nextBkg = () => {
    setBkgSettings({
      ...bkgSettings,
      selected: bkgSettings.selected < 22 ? bkgSettings.selected + 1 : 0,
      playInterval: null,
      shuffle: false,
    });
  };

  const prevBkg = () => {
    setBkgSettings({
      ...bkgSettings,
      selected: bkgSettings.selected > 0 ? bkgSettings.selected - 1 : 22,
      playInterval: null,
      shuffle: false,
    });
  };

  const addMessage = (msg, type) =>
    handleAddMessage(msg, type, messages, setMessages);

  const saveUser = (update) => {
    return axios
      .post("/updateUser", { email: user.email, user: { ...user, ...update } })
      .then((response) => {
        console.log(response.data);
        setUser(response.data);
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  const registerSuccess = () => {
    setRegVisible(false);
    setLoginVisible(true);
  };

  useEffect(() => {
    if (loading && name) setLoading(false);
  }, [name]);

  useEffect(() => {
    if (windowSize.width < 768) {
      setDeviceSize("sm");
    } else if (windowSize.width >= 768 && windowSize.width <= 992) {
      setDeviceSize("md");
    } else if (windowSize.width > 992 && windowSize.width <= 1200) {
      setDeviceSize("lg");
    } else {
      setDeviceSize("xl");
    }

    //setMobile(windowSize.width < 600);
  }, [windowSize]);

  useEffect(() => {
    let email = localStorage.getItem("email");
    let jwt = localStorage.getItem("jwt");

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    console.log(appMode);

    setMobile(!window.innerWidth < 600);
    if (email && jwt) {
      axios
        .post("/updateToken", { email })
        .then((response) => {
          let { user, token } = response.data;
          setUser(user);
          localStorage.setItem("jwt", token);
          localStorage.setItem("email", user.email);
        })
        .then(() => {
          return axios
            .post("/getName", { email })
            .then((response) => {
              setName(response.data.name);
            })
            .catch(() => setLoading(false));
        })
        .catch((err) => {
          setLoading(false);
          if (err?.code !== 401)
            parseErrors(err).then((msg) => {
              addMessage(msg, "err");
            });
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (loginVisible && regVisible) setRegVisible(false);
  }, [loginVisible]);

  useEffect(() => {
    if (regVisible && loginVisible) setLoginVisible(false);
  }, [regVisible]);

  useEffect(() => {
    if (user) setCurrentQuestion(0);
  }, [user?.currentPage]);

  return (
    <>
      {/* prettier-ignore */}
      <globalContext.Provider value={{ user, setUser, addMessage, removeMessage, messages, 
        setMessages, location, setLocation, loginVisible, setLoginVisible, regVisible, 
        setRegVisible, popupVisible, setPopupVisible, pages, setPages, saveUser, currentQuestion, 
        setCurrentQuestion, name, setName, mobile, setMobile, windowSize, deviceSize, printUser, 
        setPrintUser, users, setUsers, bkgSettings, setBkgSettings, dialog, setDialogOption, setDialog,
        appMode}} >
      <Dialog />
        <Container id="mainCentralContainer"  fluid={true}>
          <Router>
          {!printUser ? <>
            <VBackground visible={location === "/"} loginVisible={loginVisible} 
             regVisible={regVisible} appLoading={loading}/>
            <Navigation navbarFilled={true} nextBkg={nextBkg} prevBkg={prevBkg} /></> : <></>}
            <Row>
            
            <Switch>
              
              {!loading ? <>
                <Route path="/" exact={true} render={(props)=> <Home {...props} registerSuccess={registerSuccess}/>} />
                <Route path='/registercomplete' component={RegisterComplete} />
                <Route
                  path="/application"
                  exact={true}
                  component={Application}
                />
                <Route path="/dashboard" exact={true} component={Dashboard} />
                <Route path="/apanel" exact={true} component={Apanel} />
                <Route path="/pfriendly" exact={false} component={PrinterFriendly} />
                {/* <Route component={PageNotFound} /> */}
             </> : <Loading />}
              
            </Switch>
            </Row> 
          </Router>
          {!printUser ? <Footer /> : <></>}
          <ToastHolder messages={messages} />
        </Container>
      </globalContext.Provider>
    </>
  );
}

export default App;
