import { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import globalContext from "../contexts/globalContext";
import Wizard from "../components/Wizard";
import { useHistory } from "react-router-dom";
import Loading from "../components/Loading";
import axios from "axios";
import parseErrors from "../helpers/parseErrors";

import Colleges from "../components/Colleges";
import Documents from "../components/Documents";

const SidebarLabels = () => {
  const { pages, setPages, user, currentQuestion, saveUser } = useContext(
    globalContext
  );
  let currentPage = user.currentPage;

  const SubLabels = ({ questions }) => {
    if (!questions) return <></>;
    return questions.map((qs, i) => (
      <span
        key={"label" + i}
        style={{ fontWeight: currentQuestion === i ? "bold" : "initial" }}
      >
        {qs.question}
      </span>
    ));
  };

  const handleSetPage = (index) => saveUser({ currentPage: index });

  return pages.map((page, index) => {
    return (
      <>
        <li onClick={() => handleSetPage(index)}>{page.pageName}</li>
        {index === currentPage ? (
          <div className="chapter" key={"chap" + index}>
            <SubLabels questions={page.questions} />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  });
};

const Application = (props) => {
  const {
    user,
    setUser,
    addMessage,
    pages,
    setPages,
    currentQuestion,
  } = useContext(globalContext);
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  const requiredDocuments = [
    "Lease",
    "High School Transcript",
    "SAT",
    "FASFA",
    "Essay",
    "First Recomendation",
    "Second Recomendation",
  ];

  useEffect(() => {
    if (loading && pages?.[pages.length - 1]?.pageName === "Required Documents")
      setLoading(false);
  }, [pages]);

  useEffect(() => {
    if (!user) {
      addMessage("You are not logged in.", "err");
      history.push("/");
    }

    axios
      .post("/getPages")
      .then((response) => {
        //console.log("pages", response.data);
        if (response.data)
          setPages([
            ...response.data,
            {
              pageName: "Colleges",
              questions:
                user.colleges?.length > 0
                  ? user.colleges.map((qs) => {
                      return { question: qs.name };
                    })
                  : [{ question: "No colleges listed" }],
              special: true,
              component: <Colleges />,
            },
            {
              pageName: "Required Documents",
              questions: requiredDocuments.map((question) => {
                return {
                  question,
                };
              }),
              special: true,
              component: <Documents />,
            },
          ]);
      })
      .catch((err) => {
        console.log(err);
        if (err) parseErrors(err).then((msg) => console.log(msg));
      });
  }, []);

  if (loading) return <Loading fullHeight={true} />;

  return (
    <Container
      className="mainContent"
      fluid
      style={{
        backgroundColor: "rgba(255,255,255, .9)",
        height: "95vh",
        paddingTop: "6vh",
      }}
    >
      <Row style={{ height: "100%" }}>
        <Col className="sideBar paper d-none d-md-block" md={4} lg={3} xl={2}>
          <ul>
            <SidebarLabels />
          </ul>
        </Col>
        <Col
          md={8}
          lg={9}
          xl={10}
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Wizard />
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default Application;
