import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect, useState, useContext } from "react";
import globalContext from "../contexts/globalContext";
import Upload from "../components/Upload";
import axios from "axios";
import parseErrors from "../helpers/parseErrors";
import { FileEarmarkArrowDown } from "react-bootstrap-icons";
import Download from "./Download";
import Loading from "./Loading";

const SingleDocument = ({ name, fieldName, userDocument }) => {
  const [beginDownload, setBeginDownload] = useState(false);
  const { user, saveUser, addMessage } = useContext(globalContext);

  const handleFinishedUpload = (response) => {
    //console.log(response);
    saveUser({ [fieldName]: response });
  };

  const handleDeleteUserDocument = (fileName, fieldName) => {
    //console.log("delete", fileName);
    axios
      .post("/deleteDocument", {
        fileName,
        pathId: user.pathId,
        email: user.email,
      })
      .then(async () => {
        await saveUser({ [fieldName]: null });
        addMessage("Successfully removed document.", "success");
        addMessage(
          "Note: All documents on this pages are required in order to complete your application.",
          "warning"
        );
      })
      .catch((err) => {
        console.log(err);
        parseErrors(err).then((msg) => addMessage(msg, "err"));
      });
  };

  return (
    <Col md={12} lg={6} xl={4}>
      <div className="paper college">
        <Row>
          <Col xs={12}>
            <h5 style={{ position: "relative" }}>
              {name}
              {userDocument ? (
                <Badge className="collegeBadge" variant="success">
                  Completed
                </Badge>
              ) : (
                <Badge className="collegeBadge" variant="danger">
                  Incomplete
                </Badge>
              )}
            </h5>
          </Col>
          <Col>
            {!userDocument ? (
              <div className="collegeRight">
                <Upload
                  name={"upload" + fieldName}
                  caption={"Select " + name}
                  email={user.email}
                  uploadPath={"/uploadDocument"}
                  docType="document"
                  onSuccess={handleFinishedUpload}
                />
              </div>
            ) : (
              <div className="collegeRight">
                {beginDownload ? (
                  <Loading caption="Downloading..." />
                ) : (
                  <FileEarmarkArrowDown
                    onClick={() => setBeginDownload(true)}
                    style={{ fontSize: "2rem", margin: 0 }}
                  />
                )}
                <p style={{ fontSize: ".75rem", margin: 0 }}>
                  {userDocument.originalFileName.length > 35
                    ? userDocument.originalFileName.substring(0, 34) + "..."
                    : userDocument.originalFileName}
                </p>
                <div>
                  {beginDownload ? (
                    <Loading caption="Downloading..." />
                  ) : (
                    <>
                      <Button
                        style={{ padding: 0 }}
                        size="sm"
                        variant="danger"
                        onClick={() =>
                          handleDeleteUserDocument(
                            userDocument.fileName,
                            fieldName
                          )
                        }
                      >
                        Delete
                      </Button>
                      <Button
                        style={{ padding: 0 }}
                        size="sm"
                        onClick={() => setBeginDownload(true)}
                      >
                        Download
                      </Button>{" "}
                    </>
                  )}
                </div>
                <Download
                  email={user.email}
                  pathId={user.pathId}
                  doc={userDocument}
                  beginDownload={beginDownload}
                  setBeginDownload={setBeginDownload}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Col>
  );
};

const Documents = (props) => {
  const { user, saveUser } = useContext(globalContext);

  const requiredDocuments = [
    "Lease",
    "High School Transcript",
    "SAT",
    "FASFA",
    "Essay",
    "First Recomendation Letter",
    "Second Recomendation Letter",
  ];

  const fieldNames = [
    "lease",
    "transcript",
    "sat",
    "fasfa",
    "essay",
    "recommendation1",
    "recommendation2",
  ];

  if (!user) return <Loading />;

  return (
    <Container>
      <Row style={{ paddingTop: "2.5rem" }}>
        {requiredDocuments.map((doc, index) => {
          //console.log(user[fieldNames[index]]);
          return (
            <SingleDocument
              name={doc}
              fieldName={fieldNames[index]}
              userDocument={user[fieldNames[index]]}
            />
          );
        })}
      </Row>
    </Container>
  );
};

export default Documents;
