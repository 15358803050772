import { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import globalContext from "../contexts/globalContext";
import { useHistory } from "react-router-dom";
import handleLogout from "../helpers/handleLogout";
import Loading from "../components/Loading";
import axios from "axios";
import parseErrors from "../helpers/parseErrors";
import ProgressBar from "react-bootstrap/ProgressBar";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import getProgress from "../helpers/getProgress";
import Colleges from "../components/Colleges";
import Documents from "../components/Documents";
import addDocsToPages from "../helpers/addDocsToPages";

const PageStatusControl = (props) => {
  let { page, index, answers } = props;
  const [pageQuestions, setPageQuestions] = useState([]);
  const [answered, setAnswered] = useState([]);
  const [unAnswered, setUnAnswered] = useState([]);
  const [required, setRequired] = useState([]);
  const [loading, setLoading] = useState(true);

  const findAnswer = async (question) => {
    //console.log(question);
    let tempAnswer;
    if (question.type === "college" || question?.type === "document") {
      tempAnswer = question;
    } else tempAnswer = await answers.find((ans) => ans.qID == question._id);

    setPageQuestions((pageQuestions) => pageQuestions.concat(tempAnswer));
    if (question.required) {
      setRequired((required) => required.concat(tempAnswer));
      if (!tempAnswer?.answer || tempAnswer?.answer == "")
        setUnAnswered((unAnswered) => unAnswered.concat(question));
      else setAnswered((answered) => answered.concat(tempAnswer));
    }
  };

  let requiredMessage;
  switch (page.pageName) {
    case "Colleges":
      requiredMessage = "Missing acceptance letters for:";
      break;
    case "Required Documents":
      requiredMessage = "All of these documents are required.";
      break;
    default:
      requiredMessage = "Unanswered required questions";
  }

  useEffect(() => {
    //console.log(page.pageName, page.questions.length);
    page.questions.map(async (question) => await findAnswer(question));
    setLoading(false);
  }, []);

  if (loading) return <Loading />;

  return (
    <Col md={4}>
      <div className="paper college" style={{ minHeight: "5.5rem" }}>
        <p
          style={{
            backgroundColor: "#ddd",
            padding: "0 .25rem",
            borderRadius: ".20rem",
          }}
        >{`Page ${index + 1}: ${page.pageName}`}</p>
        {required?.length > 0 ? (
          <ProgressBar
            style={
              Math.floor((answered.length / required.length) * 100) < 1
                ? { backgroundColor: "darkred" }
                : {}
            }
            now={Math.floor((answered.length / required.length) * 100)}
            label={`${answered.length} out of ${required.length}`}
          />
        ) : (
          <p>These questions are optional.</p>
        )}
        <div className="collegeRight">
          {unAnswered.length ? (
            <>
              <p style={{ fontSize: ".9rem", borderBottom: "1px solid" }}>
                {requiredMessage}
              </p>
              {unAnswered.map((qs, i) => (
                <p key={"qs" + page.pageName + i}>{qs.question}</p>
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Col>
  );
};

const Dashboard = (props) => {
  const {
    user,
    setUser,
    addMessage,
    setCustomRedirect,
    pages,
    setPages,
  } = useContext(globalContext);

  const [sect1Visible, setSect1Visible] = useState(true);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [fullName, setFullName] = useState(user.email);
  const [fullAddress, setFullAddress] = useState("");
  const [highSchool, setHighSchool] = useState("");
  const [housingSite, setHousingSite] = useState("");

  const [progress, setProgress] = useState(0);
  const [targetProgress, setTargetProgress] = useState(0);

  const requiredDocuments = [
    "lease",
    "transcript",
    "sat",
    "fasfa",
    "essay",
    "recommendation1",
    "recommendation2",
  ];

  useEffect(() => {
    if (pages.length > 0)
      getProgress(pages, user.answers)
        .then(({ required, answered }) =>
          setTargetProgress(Math.floor((answered / required) * 100))
        )
        .then(() => {
          let ans = user.answers.slice();
          if (ans[0])
            setFullName(
              `${ans[0].answer}${ans[1].answer ? " " + ans[1].answer : ""} ${
                ans[2].answer ? ans[2].answer : ""
              }`
            );

          if (ans[5])
            setFullAddress(
              `${ans[5].answer},${
                ans[6].answer ? " " + ans[6].answer + "," : ""
              }${ans[7].answer ? " " + ans[7].answer + "," : ""} ${
                ans[8].answer ? ans[8].answer : ""
              }${ans[9].answer ? ", " + ans[9].answer : ""}`
            );

          if (ans[11].answer) setHousingSite(ans[11].answer);
          if (ans[17].answer) setHighSchool(ans[17].answer);
        });
  }, [pages]);

  useEffect(() => {
    setTimeout(() => {
      if (progress != targetProgress) {
        if (progress < targetProgress) {
          setProgress(progress + 5);
        } else setProgress(progress - 1);
      }
    }, 10);
  }, [progress, targetProgress]);

  useEffect(() => {
    if (!user?.email) {
      setUser(null);
      addMessage("You are not logged in.", "err");
      handleLogout(setCustomRedirect);
      history.push("/");
    }

    axios
      .post("/getPages")
      .then((response) => {
        addDocsToPages(user, response.data).then((data) => setPages(data));
      })
      .then(() => setLoading(false))
      .catch((err) => {
        console.log(err);
        if (err) parseErrors(err).then((msg) => addMessage(msg, "err"));
      });

    return setPages([]);
  }, []);

  if (loading) return <Loading fullHeight={true} />;

  return (
    <Container
      fluid
      className="mainContent"
      style={{
        backgroundColor: "rgba(255,255,255, .9)",
        height: "95vh",
        paddingTop: "6vh",
      }}
    >
      <Row style={{ height: "90vh" }}>
        <Col className="sideBar paper d-none d-md-block" md={2}>
          <ul>
            <li onClick={() => setSect1Visible(!sect1Visible)}>Settings</li>
            <div
              className="chapter"
              style={{ height: sect1Visible ? "auto" : "0" }}
            >
              <span style={{ padding: ".5rem" }}>
                Keep track of your progress here.
              </span>
            </div>
          </ul>
        </Col>
        <Col
          md={10}
          style={{
            backgroundColor: "grba(255,255,255, .8)",
          }}
        >
          <Row>
            <Col style={{ overflowY: "scroll", maxHeight: "85vh" }}>
              <Container>
                <h2 style={{ padding: ".5rem", borderBottom: "2px solid" }}>
                  Dashboard
                </h2>
                <Row>
                  <Col>
                    <Container>
                      <Row className="college paper">
                        <Col className="circleProgressBox" md={4}>
                          <div style={{ width: "50%", margin: "0 auto" }}>
                            <CircularProgressbar
                              value={progress}
                              text={`${progress}%`}
                              styles={{
                                path: {
                                  stroke: "#0c4b8c",
                                },
                                text: {
                                  fill: "#0c4b8c",
                                  fontSize: "1rem",
                                },
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <p>
                            <span className="bold">Name: </span>
                            {`${fullName}`}
                          </p>
                          <br />
                          {housingSite ? (
                            <p>
                              <span className="bold">Site: </span>
                              {`${housingSite}`}
                            </p>
                          ) : (
                            <></>
                          )}
                          <p>
                            <span className="bold">Address: </span>
                            {`${fullAddress}`}
                          </p>
                          <p>
                            <span className="bold">School: </span>
                            {`${highSchool}`}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        {pages.map((page, i) => (
                          <PageStatusControl
                            key={"pageStatus" + i}
                            page={page}
                            index={i}
                            answers={user.answers}
                          />
                        ))}
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
