import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import globalContext from "../contexts/globalContext";

const RegisterComplete = (props) => {
  const history = useHistory();
  let { addMessage } = useContext(globalContext);
  let params = new URLSearchParams(props.location.search);
  let email = params.get("email");
  let token = params.get("token");

  useEffect(() => {
    if (email && token) {
      axios
        .post("/registerComplete", { email, token })
        .then(() => {
          addMessage(
            `You have successfully registered account ${email}!`,
            "success"
          );
          history.push("/");
        })
        .catch((err) => {});
    }
  }, []);
  return <></>;
};

export default RegisterComplete;
