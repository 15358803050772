import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState, useContext } from "react";
import globalContext from "../contexts/globalContext";

const Dialog = () => {
  const { dialog, setDialog } = useContext(globalContext);
  let { show, type, title, message, handleClose, handleConfirm } = dialog;
  return (
    <Modal show={show} size="sm" onHide={handleClose} keyboard={true}>
      <Modal.Header className="winFormTitle paper" closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginTop: "2rem" }}>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Dialog;
