import { useState, useEffect } from "react";
import Toast from "react-bootstrap/Toast";
import timeSince from "../helpers/timeSince";

const CustomToast = (props) => {
  let [show, setShow] = useState(true);
  let [dateCreated, setDateCreated] = useState(null);
  let [expires, setExpires] = useState(15000);

  let headerStyle = {
    color: "white",
    backgroundColor: null,
  };

  let handleClose = () => {
    setShow(false);

    setTimeout(() => {
      props.removeMessage(props.id);
    }, 1000);
  };

  useEffect(() => {
    if (props.expires) setExpires(props.expires);

    setInterval(() => {
      setDateCreated(timeSince(props.dateCreated));
      if (Date.now() - props.dateCreated >= expires) handleClose();
    }, 5000);
  }, []);

  let title = props.title;

  switch (props.type) {
    case "warning":
      headerStyle.color = "black";
      headerStyle.backgroundColor = "var(--warning)";
      if (!props.title) {
        title = "Warning";
      }
      break;
    case "err":
      headerStyle.color = "white";
      headerStyle.backgroundColor = "var(--danger)";
      if (!props.title) {
        title = "Error";
      }
      break;
    case "error":
      headerStyle.color = "white";
      headerStyle.backgroundColor = "var(--danger)";
      if (!props.title) {
        title = "Error";
      }
      break;
    case "success":
      headerStyle.color = "black";
      headerStyle.backgroundColor = "var(--success)";
      if (!props.title) {
        title = "Success";
      }
      break;
    default:
      headerStyle = {
        backgroundColor: "var(--primColor)",
        color: "white",
      };
      if (!props.title) {
        title = "Message";
      }
  }

  return (
    <Toast
      id={props.id}
      className="customToast"
      onClose={() => handleClose()}
      show={show}
    >
      <Toast.Header style={headerStyle}>
        <strong className="mr-auto customToastTitle">{title}</strong>
        <small className="customToastTime">
          {dateCreated ? dateCreated + " ago" : "New Message"}
        </small>
      </Toast.Header>
      <Toast.Body>{props.message}</Toast.Body>
    </Toast>
  );
};

export default CustomToast;
