const checkFailedLogins = (failedLogins, addMessage, setReset) => {
  if (failedLogins === 3) {
    addMessage(
      'Did you forget your password?  Click "Forgot Password" under the Login button.',
      "warning"
    );
  }
  if (failedLogins === 5) {
    addMessage(
      "Seriously, I can just reset your password for you.  I promise you its no big deal.",
      "warning"
    );
  }
  if (failedLogins === 7) {
    addMessage("You're just being stubborn at this point...", "warning");
    setReset(true);
  }
  if (failedLogins === 10) {
    addMessage(
      "You can bring a horse to water but you can't make him drink it...",
      "warning"
    );
    setReset(true);
  }
  if (failedLogins === 12) {
    addMessage(
      "Contact your administrator.  You're clearly having administrator level problems right now.",
      "err"
    );
    setReset(true);
  }
};

export default checkFailedLogins;
