import Axios from "axios";
import handleLogout from "./handleLogout";

class timeKeeper {
  constructor() {
    this.timeLimit = 5000; //this is how many miliseconds before it counts as a view
    this.timeLastUpdated = 0; //how long they have viewed the video
    this.timeIncrement = 1000; //how often to check if they're still playing the video
  }

  checkTimer = () => {
    this.timeLastUpdated = this.timeLastUpdated + this.timeIncrement;
    if (this.timeLastUpdated === 1800000) {
      handleLogout();
    }
  };

  restartTimer = () => {
    this.timeLastUpdated = 0;
    clearInterval(this.timer);
    this.timer = setInterval(this.checkTimer, 1000);
  };

  timer = setInterval(this.checkTimer, 1000);
}

export default timeKeeper;
