import Button from "react-bootstrap/Button";
import { useEffect, useRef, useState, useContext } from "react";
import globalContext from "../contexts/globalContext";
import "../style/login.css";
import handleLogout from "../helpers/handleLogout";
import { useHistory } from "react-router-dom";
import Draggable from "react-draggable";

const Popup = (props) => {
  const defaultRef = useRef(null);
  const { user, name, popupVisible, setPopupVisible } = useContext(
    globalContext
  );
  const history = useHistory();

  useEffect(() => {
    if (popupVisible) defaultRef.current.focus();
  }, []);

  if (!popupVisible) return <></>;
  let nickName = name?.fName?.length ? name.fName : user.email.split("@")[0];
  return (
    <Draggable disabled={window.innerWidth < 780}>
      <form className="winForm paper" style={{ animation: "fadeIn 1s" }}>
        <div className="winFormTitle paper handle">
          Continue
          <button
            className="closeBtn circleButton paper"
            onClick={() => setPopupVisible(false)}
            type="button"
          >
            &nbsp;
          </button>
        </div>

        <div className="winFormContent">
          <h5 style={{ margin: "1rem", textAlign: "center" }}>
            {`Welcome back to the NHASF Scholarship Application, ${nickName}.  The following documents are required to complete your application:`}
          </h5>

          <div style={{ padding: 10 }}>
            1. Current Lease (student must be on lease to qualify)
            <br />
            2. High School Transcript
            <br />
            3. SAT/ACT scores
            <br />
            4. Completed FASFA Application
            <br />
            5. 300 Word typed essay on one of the following subjects
            <br />
            <p style={{ paddingLeft: 20, margin: 0 }}>
              1. What impact would this scholarship have on your education and
              why?
              <br />
              2. How will your study of choice contribute to your immediate or
              long-term career plans?
              <br />
              3. If you had the authority to change your community in a positive
              way, what specific changes would you make?
              <br />
              4. What was the biggest mistake you have made?
            </p>
            6. College acceptance letters
            <br />
            7. 2 recommendation letters
          </div>
        </div>
        <Button
          ref={defaultRef}
          style={{ margin: "1rem" }}
          type="submit"
          onClick={() => history.push("/application")}
        >
          Continue Application
        </Button>
        <a
          onClick={() => handleLogout()}
          style={{ color: "red" }}
          href="#"
        >{`I am not ${nickName}.`}</a>
      </form>
    </Draggable>
  );
};

export default Popup;
