import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect, useRef, useContext } from "react";
import globalContext from "../contexts/globalContext";
import Toggle from "./Toggle";
import "../style/login.css";
import axios from "axios";
import parseErrors from "../helpers/parseErrors";
import { useHistory } from "react-router-dom";
import Draggable from "react-draggable";
import checkFailedLogins from "../helpers/checkFailedLogins";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [reset, setReset] = useState(false);
  const [failedLogins, setFailedLogins] = useState(0);
  let history = useHistory();

  const {
    user,
    setUser,
    addMessage,
    loginVisible,
    setLoginVisible,
  } = useContext(globalContext);

  const defaultRef = useRef(null);

  const handleTgl = (name, on) => {
    setShowPassword(on);
  };

  useEffect(() => {
    checkFailedLogins(failedLogins, addMessage, setReset);
  }, [failedLogins]);

  const handleReset = () => {
    axios.post("/resetPassword", { email });
    addMessage(
      "You will receive an email from the NHASF with a link to reset your password. If not, you may need to register or contact your administrator.",
      "warning"
    );
    setReset(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (reset) return handleReset();
    let errors = [];
    if (!email) errors.push("Email cannot be empty.");
    if (!password) errors.push("Password cannot be empty.");
    if (!errors || errors.length === 0) {
      //No errors so try the login route
      axios
        .post("/login", { email, password })
        .then((response) => {
          let { user, jwt } = response.data;
          setUser(user);
          localStorage.setItem("email", email);
          localStorage.setItem("jwt", jwt);

          axios.defaults.headers.common = {
            Authorization: `bearer ${jwt}`,
          };

          //if the user is admin, send them to the admin panel UNLESS its their first login
          //all other users get sent tot he last page the were at
          history.push(
            user.accountLevel > 1 && user.currentPage !== 0
              ? "/aPanel"
              : "/application"
          );
          setLoginVisible(false);
        })
        .catch((err) => {
          parseErrors(err).then((msg) => addMessage(msg, "err"));
          setFailedLogins(failedLogins + 1);
        });
    } else {
      //loop through the errors and create popup messages for them
      console.log(errors);
      errors.map((err) => {
        addMessage(err, "error");
      });
    }
  };

  useEffect(() => {
    if (loginVisible) defaultRef.current.focus();
  }, []);

  if (!loginVisible) return <></>;

  return (
    <Draggable disabled={window.innerWidth < 780}>
      <form className="winForm paper" style={{ animation: "fadeIn 1s" }}>
        <div className="winFormTitle paper handle">
          Login
          <button
            className="closeBtn circleButton paper"
            onClick={() => setLoginVisible(false)}
            type="button"
          >
            &nbsp;
          </button>
        </div>
        <Container>
          <div className="winFormContent">
            <Row>
              <Col>
                <h5 style={{ margin: "1rem" }}>
                  This wizard will help you apply for a scholarship with the
                  NHASF. If you cannot complete a section or need to gather more
                  documents to upload, you can save your progress and come back
                  later.
                </h5>
              </Col>
            </Row>
            <Row>
              <Col className="vCenter">
                <Row style={{ margin: "auto" }}>
                  <Col xs={12}>
                    <input
                      style={{ textTransform: "lowercase" }}
                      placeholder="Email"
                      ref={defaultRef}
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    />
                  </Col>
                  {!reset ? (
                    <Col xs={12}>
                      <input
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        defaultValue={password}
                        onChange={(e) => setPassword(e.target.value)}
                      ></input>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </Col>

              {!reset ? (
                <Col
                  className="vCenter"
                  style={{ borderLeft: "1px solid silver" }}
                >
                  <Toggle
                    name={"showPassword"}
                    on={showPassword}
                    caption="Show Password"
                    handleTgl={handleTgl}
                  />
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </div>
        </Container>
        <Button style={{ margin: "1rem" }} type="submit" onClick={handleLogin}>
          {reset ? "Send reset code" : "Login"}
        </Button>
        <a href="#">
          <p style={{ margin: "1rem" }} onClick={() => setReset(!reset)}>
            {reset ? "Let me try to log in again." : "Forgot my password"}
          </p>
        </a>
      </form>
    </Draggable>
  );
};

export default Login;
