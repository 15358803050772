import { useState, useEffect, useContext, useRef } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import globalContext from "../contexts/globalContext";
import axios from "axios";
import parseErrors from "../helpers/parseErrors";
import handleLogout from "../helpers/handleLogout";
import PageEditor from "../components/PageEditor";
import UserManagement from "../components/UserManagement";
import Loading from "../components/Loading";

const Apanel = () => {
  const { user, users, pages, setPages, addMessage } = useContext(
    globalContext
  );
  const [sect1Visible, setSect1Visible] = useState(true);
  const [loading, setLoading] = useState(true);

  const [userStats, setUserStats] = useState({
    complete: 0,
    almostComplete: 0,
    barelyStarted: 0,
    inBetween: 0,
  });

  const getUserStats = async () => {
    console.log("getting stats", users);

    let admins = await users.filter((usr) => usr.accountLevel > 0);
    let complete = await users.filter(
      (usr) => usr.complete === 100 && usr.accountLevel < 1
    );
    let barelyStarted = await users.filter(
      (usr) => usr.complete < 40 && usr.accountLevel < 1
    );
    let inBetween = await users.filter(
      (usr) => usr.complete >= 40 && usr.complete < 70 && usr.accountLevel < 1
    );
    let almostComplete = await users.filter(
      (usr) => usr.complete >= 70 && usr.complete < 99 && usr.accountLevel < 1
    );

    Promise.all([
      complete,
      almostComplete,
      barelyStarted,
      inBetween,
      admins,
    ]).then((data) => {
      console.log("users:", users);
      console.log("data:", data);
      setUserStats({
        ...userStats,
        complete: data[0].length,
        almostComplete: data[1].length,
        barelyStarted: data[2].length,
        inBetween: data[3].length,
        admins: data[4].length,
      });
    });
  };

  useEffect(() => {
    if (loading && pages?.length > 0) setLoading(false);
  }, [pages]);

  useEffect(() => {
    if (!user?.email || user.accountLevel < 1) {
      addMessage("You are not logged in.", "err");
      handleLogout();
      window.location = "/";
      return;
    }

    axios
      .post("/getPages")
      .then((response) => {
        setPages(response.data);
      })
      .catch((err) => {
        if (err) parseErrors(err).then((msg) => addMessage(msg, "err"));
      });
  }, []);

  if (loading) return <Loading />;

  return (
    <Container
      fluid
      className="mainContent"
      style={{
        backgroundColor: "rgba(255,255,255, .9)",
        height: "95vh",
        paddingTop: "6vh",
      }}
    >
      <Row style={{ height: "90vh" }}>
        <Col className="sideBar paper" md={2}>
          <ul>
            <li onClick={() => setSect1Visible(!sect1Visible)}>Settings</li>
            <div
              className="chapter"
              style={{ height: sect1Visible ? "auto" : "0" }}
            >
              <span style={{ padding: ".5rem" }}>
                <span style={{ fontWeight: "bold" }}>User Management</span>
                shows you the status of all users. You can reset passwords,
                activate accounts, and view general information.
                <hr></hr>
                <span style={{ fontWeight: "bold" }}>Application Stats</span>
                <p>
                  Note: Stats will no longer count administrators. All stats are
                  for student applications only.
                </p>
                {`Admins: ${userStats.admins}`}
                <hr />
                {`Total Students: ${users.length - userStats.admins || 0}`}
                <br />
                {`Complete: ${userStats.complete}`}
                <br />
                {`Almost Complete: ${userStats.almostComplete}`}
                <br />
                {`In-Between: ${userStats.inBetween}`}
                <br />
                {`Barely Started: ${userStats.barelyStarted}`}
                <br />
              </span>
              <span style={{ padding: ".5rem", borderTop: "1px solid #ccc" }}>
                <span style={{ fontWeight: "bold" }}>Page Editor</span>allows
                you to create and edit pages of questions.
              </span>
            </div>
          </ul>
        </Col>
        <Col
          md={10}
          style={{
            backgroundColor: "grba(255,255,255, .8)",
          }}
        >
          <Row>
            <Col style={{ overflowY: "scroll", maxHeight: "90vh" }}>
              <Container>
                <h2 style={{ padding: ".5rem", borderBottom: "2px solid" }}>
                  Admin Control Panel
                </h2>
                <Row>
                  <Col>
                    <div
                      className="college paper"
                      style={{ backgroundColor: "white" }}
                    >
                      <UserManagement getUserStats={getUserStats} />
                    </div>
                    <div
                      className="college paper"
                      style={{ backgroundColor: "white" }}
                    >
                      <PageEditor />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Apanel;
