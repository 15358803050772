const parseErrors = (rawError) => {
  //console.log(rawError.response.data.msg);
  return new Promise((res, rej) => {
    if (rawError?.response?.msg) {
      return res(rawError.response.msg);
    }

    if (rawError?.response?.data?.msg) {
      return res(rawError.response.data.msg);
    }

    if (rawError?.data?.msg) {
      return res(rawError?.data?.msg);
    }

    if (rawError?.data && !rawError.data.msg) {
      return res(rawError.data[0] ?? rawError.data);
    }

    if (rawError?.msg) {
      return res(rawError.msg);
    }

    if (rawError?.response?.data?.msg) {
      return res(rawError.response.data.msg);
    }

    if (rawError?.response?.data) {
      return res(rawError.response.data);
    }

    if (rawError?.response?.statusText) {
      let { status, statusText } = rawError.response;
      return res(`${status} error: ${statusText}`);
    }

    if (rawError?.code) {
      let msg;
      switch (rawError.code) {
        case 11000:
          msg = "User already exists.";
          break;
        default:
          msg = "An unknown error has occurred.";
      }
      return res(msg);
    }

    let err = [];
    if (typeof rawError === "object") {
      if (Array.isArray(rawError)) {
        rawError.map((single) => {
          err = err + single + "\n";
        });
        return res(err);
      } else {
        return res(JSON.stringify(rawError));
      }
    } else {
      return res(rawError);
    }
  });
};

module.exports = parseErrors;
