import { useState, useEffect, useContext } from "react";
import globalContext from "../contexts/globalContext";
import getRandInt from "../helpers/getRandInt";
import useInterval from "../helpers/useInterval";

const VBackground = ({ visible, loginVisible, regVisible, appLoading }) => {
  const { bkgSettings, setBkgSettings } = useContext(globalContext);
  let { selected, playInterval, defaultPlayInterval, shuffle } = bkgSettings;

  let setBkg = (change) => setBkgSettings({ ...bkgSettings, ...change });

  let shade = document.getElementsByClassName("innerMainContent")[0];

  let getNum = async () => {
    setBkg({ selected: await getRandInt(0, 22) });
  };

  useInterval(() => {
    if (shuffle) {
      getRandInt(0, 22).then((num) => setBkg({ selected: num }));
    } else {
      setBkg({
        selected: bkgSettings.selected < 22 ? bkgSettings.selected + 1 : 0,
      });
    }
  }, playInterval);

  useEffect(() => {
    getNum();
    setBkg({ playInterval: defaultPlayInterval, shuffle: true });
  }, []);

  useEffect(() => {
    if (!shade) return;
    if (!loginVisible && !regVisible && !appLoading) {
      shade.style.backgroundColor = "rgba(0, 0, 0, 0.3)";
    } else {
      shade.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
    }
    console.log(shade.style);
  }, [loginVisible, regVisible]);

  return (
    <div
      id="vidBackground"
      className="hCenter"
      style={{
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "black",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        backgroundImage: `url("golf(${selected}).JPG")`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        margin: 0,
        padding: 0,
      }}
    >
      {/* <video
        id="vid"
        style={{
          // maxHeight: "100vh",
          width: "100vw",
          minHeight: "100vh",
          margin: "0 auto",
        }}
        muted
        loop
        src="golf.mp4#t=100,105"
      ></video> */}
    </div>
  );
};

export default VBackground;
