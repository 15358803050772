const getProgress = async (pages, answers) => {
  // console.log(pages);
  // console.log(answers);
  let required = 0;
  let answered = 0;
  let finalAnswers = [];

  //return { required: 20, answered: 18, finalAnswers: {} };
  const findSingleAnswer = async (question, answers) => {
    let tempAnswer;
    if (question.type === "college" || question?.type === "document") {
      tempAnswer = question;
    } else tempAnswer = await answers.find((ans) => ans.qID == question._id);

    if (question.required) {
      required = required + 1;
      if (tempAnswer.answer && tempAnswer.answer !== "")
        answered = answered + 1;
    }
    return tempAnswer?.answer || "";
  };

  const findAnswers = async (questions) => {
    let tempAnswers = [];
    await questions.map(async (question) => {
      let answer = await findSingleAnswer(question, answers);
      tempAnswers.push({
        question: question.question,
        answer,
        type: question.type ?? "qa",
      });
    });
    return tempAnswers;
  };

  for (let i = 0; i < pages.length; i++) {
    let answers = await findAnswers(pages[i].questions);
    //console.log(answers);
    finalAnswers.push(answers);
    if (i + 1 === pages.length) {
      return { required, answered, finalAnswers };
    }
  }
};

export default getProgress;
