import { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";

const Footer = (props) => {
  const [year, setYear] = useState(null);

  useEffect(() => {
    let d = new Date().getFullYear();
    setYear(d);
  }, []);

  return (
    <Navbar sticky="bottom" className="footer">
      {`© ${year} Newark Housing Authority.`}
      <br />
      All rights reserved.
    </Navbar>
  );
};

export default Footer;
