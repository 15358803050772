import { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LinkContainer } from "react-router-bootstrap";
import Image from "react-bootstrap/Image";
import globalContext from "../contexts/globalContext";
import { useHistory } from "react-router-dom";
import PlayerControls from "./PlayerControls";

import handleLogout from "../helpers/handleLogout";
import Button from "react-bootstrap/esm/Button";

const Navigation = ({ navbarFilled, nextBkg, prevBkg }) => {
  const history = useHistory();
  const location = history.location.pathname;

  const {
    user,
    addMessage,
    loginVisible,
    setLoginVisible,
    regVisible,
    setRegVisible,
    popupVisible,
    deviceSize,
    dialog,
    setDialog,
    appMode,
  } = useContext(globalContext);

  let answers;
  if (user) answers = user.answers;

  const active = { color: "goldenrod !important" };
  let showControls = !popupVisible && !regVisible && !loginVisible;

  const [scrollPosition, setScrollPosition] = useState(
    document.body.scrollTop || document.documentElement.scrollTop
  );

  const checkScrollPos = (e) => {
    setScrollPosition(
      document.body.scrollTop || document.documentElement.scrollTop
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPos);
  }, []);

  return (
    <Navbar
      className={`navbarFilled ${showControls ? "transparent" : "opaque"}`}
      expand="lg"
      fixed="top"
    >
      <Navbar.Brand href="/">
        <Image
          src="nhaMedium.png"
          className="circleButton ripple"
          roundedCircle
        />
        NHASF Scholarship Application
        <Image
          style={{ display: deviceSize == "sm" ? "none" : "initial" }}
          src="nhasf.png"
          className="circleButton"
          roundedCircle
        />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {appMode === "development" ? (
          <h4 style={{ margin: "auto", display: "block", color: "red" }}>
            [ DEVELOPMENT ]
          </h4>
        ) : (
          <></>
        )}
        <Nav className="mr-auto">
          {user ? (
            <>
              <LinkContainer to="/">
                <Nav.Link style={location === "/" ? active : {}} href="/">
                  Home
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/application">
                <Nav.Link style={location === "/application" ? active : {}}>
                  Application
                </Nav.Link>
              </LinkContainer>
              <LinkContainer
                style={location === "/dashboard" ? active : {}}
                to="/dashboard"
              >
                <Nav.Link>Dashboard</Nav.Link>
              </LinkContainer>
              {user?.accountLevel > 0 && deviceSize !== "sm" ? (
                <LinkContainer to="/apanel">
                  <Nav.Link style={location === "/apanel" ? active : {}}>
                    Admin Panel
                  </Nav.Link>
                </LinkContainer>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Nav>
        {location === "/" && !regVisible && !loginVisible && !popupVisible ? (
          <PlayerControls nextBkg={nextBkg} prevBkg={prevBkg} />
        ) : (
          <></>
        )}
        {!user ? (
          <>
            <Button
              disabled={loginVisible}
              onClick={() => setLoginVisible(true)}
            >
              Login
            </Button>
            <Button disabled={regVisible} onClick={() => setRegVisible(true)}>
              Register
            </Button>
          </>
        ) : (
          <></>
        )}

        {user ? (
          <NavDropdown
            title={
              answers[0]?.answer && answers[2]?.answer ? (
                `${answers[0].answer} ${answers[2].answer}`
              ) : (
                <>{user.email}</>
              )
            }
            id="basic-nav-dropdown"
          >
            {/* <NavDropdown.Item
              href="#"
              onClick={() => history.push("/dashboard")}
            >
              Dashboard
            </NavDropdown.Item> */}

            {/* <NavDropdown.Item href="#action/3.2">Messages</NavDropdown.Item> */}
            {/* <NavDropdown.Divider /> */}
            <NavDropdown.Item onClick={() => handleLogout()}>
              Log out
            </NavDropdown.Item>
          </NavDropdown>
        ) : (
          <></>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
