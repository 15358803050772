import React from "react";
import "../style/toggle.css";

export default class Toggle extends React.Component {
  constructor(props) {
    super();
    this.state = { on: false, caption: "" };
  }

  componentDidMount() {
    if (this.props.options) {
      let tempOptions = this.props.options.split("|");
      this.setState({
        trueCaption: tempOptions[0],
        falseCaption: tempOptions[1],
      });
    }
    this.setState({ on: this.props.on, caption: this.props.caption });
  }

  componentDidUpdate() {
    if (this.props.on !== this.state.on) {
      this.setState({ on: this.props.on });
    }
  }

  render() {
    let offColor = "silver";
    let onColor = "#0c4b8c";
    document.documentElement.style.setProperty("--on-color", onColor);
    let tglContainer;
    let tglSwitch;
    let tglLabelOn;
    let tglLabelOff;

    if (this.state.on) {
      tglContainer = { border: "1px solid " + onColor };
      tglSwitch = { left: "3.3rem" };
      tglLabelOn = { left: "0" };
      tglLabelOff = { left: "4rem" };
    } else {
      tglContainer = { border: "1px solid " + offColor };
      tglSwitch = { left: "0" };
      tglLabelOn = { left: "-4rem" };
      tglLabelOff = { left: "0" };
    }

    let on = this.state.on;
    let name = this.props.name;
    tglContainer.display = "flex";

    return (
      <div
        className="tglBox vCenter"
        style={this.props?.style}
        onClick={(e) => {
          e.stopPropagation();
          this.props.handleTgl(name, !on);
        }}
      >
        <div
          name={this.props.name}
          className="tglContainer"
          style={(tglContainer, this.props.short ? { height: "1.3rem" } : {})}
          tabIndex={this.props.tabIndex ?? "unset"}
        >
          <div className="tglLabelOn" style={tglLabelOn}>
            {this.state?.trueCaption || "On"}
          </div>
          <div className="tglSwitch" style={tglSwitch} />

          <div className="tglLabelOff" style={tglLabelOff}>
            {this.state?.falseCaption || "Off"}
          </div>
        </div>
        <div className="tglCaption">{this.state.caption}</div>
      </div>
    );
  }
}
