import React, { useEffect } from "react";
import Axios from "axios";

const Download = (props) => {
  let { email, doc, pathId, beginDownload, setBeginDownload } = props;
  let { originalFileName, fileName } = props.doc;

  useEffect(() => {
    if (beginDownload === true) {
      //console.log("lets download file", fileName, originalFileName, email);
      return Axios({
        url: "/docs",
        method: "POST",
        data: { email, fileName, originalFileName, pathId },
        responseType: "blob",
      }).then((response) => {
        //console.log(response.data);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", originalFileName);
        document.body.appendChild(link);
        link.click();
        setBeginDownload(false);
      });
    }
  }, [props]);

  return <></>;
};

export default Download;
