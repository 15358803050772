const removeMessage = (id, messages, setMessages) => {
  if (!Array.isArray(messages) || !messages) {
    return;
  }

  for (var i; i < messages.length; i++) {
    if (messages[i].id === id) {
      let newMessages = messages.splice(i, 1);
      setMessages(newMessages);
      break;
    }
  }
};

export default removeMessage;
