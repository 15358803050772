import { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import {
  CaretLeftFill,
  CaretRightFill,
  SkipStartFill,
  SkipEndFill,
} from "react-bootstrap-icons";
import QA from "../components/QA";
import globalContext from "../contexts/globalContext";
import Loading from "./Loading";

const PageContents = ({
  questions,
  currentPage,
  currentQuestion,
  special,
  component,
}) => {
  if (special) {
    // console.log(questions, currentPage, currentQuestion, special, component);
    return component;
  }

  const listOfQuestions = questions.map((question, index) => {
    return (
      <QA
        key={`qaPg${currentPage}Qs${index}`}
        question={question}
        num={index}
        active={index === currentQuestion && window.innerWidth > 1000}
        index={index}
      />
    );
  });

  return <div className="wizardCenterContent">{listOfQuestions}</div>;
};

const Wizard = () => {
  const { pages, setPages, user, saveUser, currentQuestion } = useContext(
    globalContext
  );
  const [loading, setLoading] = useState(true);
  let currentPage = user.currentPage;

  const setCurrentPage = (pageNum) => {
    if (pageNum === currentPage) return;
    setLoading(true);
    saveUser({ currentPage: pageNum });
  };

  const handleNext = () =>
    currentPage < pages.length - 1 ? setCurrentPage(currentPage + 1) : null;

  const handleFirst = () => setCurrentPage(0);
  const handleLast = () => setCurrentPage(pages.length - 1);
  const handleBack = () =>
    currentPage > 0 ? setCurrentPage(currentPage - 1) : null;

  useEffect(() => {
    if (loading && user && pages?.length > 0) setLoading(false);
  }, [user, pages]);

  if (loading) return <Loading />;

  return (
    <div className="wizard paper">
      <div className="titleBar paper">
        <h5>Application Wizard</h5>
      </div>
      <div className="sectionTitle paper">{pages[currentPage].pageName}</div>
      {!loading ? (
        <PageContents
          questions={pages[currentPage].questions}
          currentPage={currentPage}
          special={pages[currentPage].special}
          currentQuestion={currentQuestion}
          component={pages[currentPage]?.component}
        />
      ) : (
        <Loading />
      )}

      <div className="buttonBar paper">
        <div>
          <Button disabled={currentPage === 0} onClick={handleFirst}>
            <SkipStartFill fill="white" />
          </Button>
          <Button disabled={currentPage === 0} onClick={handleBack}>
            <CaretLeftFill fill="white" />
            Previous
          </Button>
        </div>
        <div>
          <Button
            disabled={currentPage >= pages.length - 1}
            onClick={handleNext}
          >
            Next <CaretRightFill fill="white" />
          </Button>
          <Button
            disabled={currentPage >= pages.length - 1}
            onClick={handleLast}
          >
            <SkipEndFill fill="white" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Wizard;
