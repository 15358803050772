import { useState, useEffect, useContext } from "react";
import Toggle from "./Toggle";
import Container from "react-bootstrap/esm/Container";
import QaSetting from "./QA";
import Button from "react-bootstrap/Button";
import axios from "axios";
import globalContext from "../contexts/globalContext";
import parseErrors from "../helpers/parseErrors";
import { PlusCircle } from "react-bootstrap-icons";
import Badge from "react-bootstrap/Badge";

const blankQuestion = {
  question: "",
  qType: "Text",
};

const QuestionList = ({ questions, setQuestions, pageNumber, addQuestion }) => {
  if (!questions.length || questions.length === 0) {
    return (
      <>
        <p>There are no questions. </p>

        <Button onClick={addQuestion} variant="success" size="sm">
          + Add Question
        </Button>
      </>
    );
  }

  const editQuestion = async (index, value) => {
    let newQuestions = await questions.map((question, i) =>
      i === index ? { ...question, ...value } : question
    );
    console.log(newQuestions);
    setQuestions(pageNumber, newQuestions);
  };

  return questions.map((qs, index) => {
    let {
      question,
      required,
      qType,
      options,
      disabled,
      defaultAnswer,
      _id,
      reliesOn,
    } = qs;

    if (qType === "Toggle" && !defaultAnswer) defaultAnswer = false;
    return (
      <div className="singleQuestionEdit" key={String(_id)}>
        <input
          defaultValue={question}
          onChange={(e) => editQuestion(index, { question: e.target.value })}
          placeholder="Type a question here"
        />

        <select
          defaultValue={qType}
          onChange={(e) => editQuestion(index, { qType: e.target.value })}
          placeholder="Type of question"
        >
          <option value="Text">Text</option>
          <option value="Text Area">Text Area</option>
          <option value="Phone">Phone</option>
          <option value="Number">Number</option>
          <option value="Dropdown">Dropdown</option>
          <option value="Toggle">Toggle</option>
        </select>

        <Toggle
          name={"required"}
          on={required}
          options={"Yes|No"}
          caption="Required?"
          handleTgl={() => editQuestion(index, { required: !required })}
        />

        {qType === "Toggle" ? (
          <Toggle
            name={"defaultAnswer"}
            on={defaultAnswer}
            caption="Default Answer"
            handleTgl={() =>
              editQuestion(index, { defaultAnswer: !defaultAnswer })
            }
          />
        ) : (
          <input
            defaultValue={defaultAnswer}
            onChange={(e) =>
              editQuestion(index, { defaultAnswer: e.target.value })
            }
            placeholder="Default answer"
          />
        )}
        {qType === "Dropdown" ? (
          <>
            <input
              defaultValue={options}
              onChange={(e) => editQuestion(index, { options: e.target.value })}
              placeholder={`Option 1, Option 2, ...`}
            />
          </>
        ) : (
          <></>
        )}

        <select
          name={"reliesOn"}
          value={reliesOn}
          onChange={(e) => editQuestion(index, { reliesOn: e.target.value })}
        >
          <option value="">Doesn't rely on other fields</option>
          {questions.map((option, index) => (
            <option value={option.question.trim()} key={String(_id) + index}>
              {option.question.trim()}
            </option>
          ))}
        </select>
      </div>
    );
  });
};

const PageComponents = ({ collapsed }) => {
  const { pages, setPages, addMessage, user } = useContext(globalContext);

  const editPage = (page, index) => {
    console.log(page, index);
    axios
      .post("/savePage", { page, email: user.email })
      .then(async (response) => {
        console.log(response.data.page, index);
        let tempPages = await pages.slice();
        tempPages[index] = response.data.page;
        console.log(tempPages);
        setPages(tempPages);
        console.log("Successfully edited page");
        return response.data.page;
      })
      .catch((err) => parseErrors(err).then((msg) => addMessage({ msg })));
  };

  const setQuestions = (pageNumber, changes) => {
    console.log(pageNumber, pages);
    editPage({ ...pages[pageNumber], questions: [...changes] }, pageNumber);
  };

  const addQuestion = (index) => {
    let page = pages[index];
    page.questions.push(blankQuestion);
    axios
      .post("/savePage", { page, email: user.email })
      .then((response) => {
        let newPage = response.data.page;
        let tempPages = pages.slice();
        tempPages[index] = newPage;
        let qIndex = tempPages.length - 1;
        setPages(tempPages);
        console.log(newPage.questions[newPage.questions.length - 1], qIndex);
        axios
          .post("/addQuestionToUsers", {
            email: user.email,
            question: newPage.questions[newPage.questions.length - 1],
          })
          .then((response) => console.log("newUser", response.data))
          .catch((err) => parseErrors(err).then((msg) => addMessage({ msg })));
      })
      .catch((err) => parseErrors(err).then((msg) => addMessage({ msg })));
  };
  if (collapsed) return <></>;
  return pages.map((page, index) => {
    return (
      <PageEditorContainer
        key={`pgSetting${index}`}
        page={page}
        index={index}
        setQuestions={setQuestions}
        addQuestion={addQuestion}
        editPage={editPage}
      />
    );
  });
};

const PageEditorContainer = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const { page, index, setQuestions, addQuestion, editPage } = props;

  return (
    <div className="PageEditorContainer paper" key={"pageEditor" + index}>
      <p
        className="PageEditorLabel"
        style={{
          backgroundColor: `rgba(var(${
            collapsed ? "--primDarkColor" : "--primColor"
          } ), 1)`,
        }}
        onClick={() => setCollapsed(!collapsed)}
      >
        {`Page ${index + 1}`}
        <input
          placeholder={page.pageName}
          defaultValue={page.pageName}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) =>
            editPage({ ...page, pageName: e.target.value }, index)
          }
        />
      </p>
      <div
        className="PageEditorContent"
        style={{
          display: collapsed ? "none" : "initial",
          animation: "fadeIn 1s",
        }}
      >
        {!page?.questions?.length || page.questions.length === 0 ? (
          <>
            <p style={{ textAlign: "center" }}>There are no questions. </p>
          </>
        ) : (
          <QuestionList
            questions={page.questions}
            pageNumber={index}
            setQuestions={setQuestions}
            addQuestion={addQuestion}
          />
        )}
        <Button
          onClick={() => addQuestion(index)}
          variant="success"
          size="sm"
          style={{ display: "block", margin: "auto" }}
        >
          + Add Question
        </Button>
      </div>
    </div>
  );
};

const PageEditor = () => {
  const { user, addMessage, pages, setPages } = useContext(globalContext);
  const [collapsed, setCollapsed] = useState(true);
  let currentPage = user.currentPage;

  const addPage = () => {
    axios
      .post("/addPage")
      .then((response) => {
        console.log(response.data.page);
        setPages([...pages, response.data.page]);
      })
      .catch((err) => {
        console.log(err);
        parseErrors(err).then((msg) => addMessage(msg, "err"));
      });
  };

  return (
    <>
      <h4
        className="adminPanelSectionLabel"
        onClick={() => setCollapsed(!collapsed)}
      >
        Page Editor
        {collapsed ? (
          <Badge
            style={{
              fontSize: ".75rem",
              float: "right",
              backgroundColor: "gray",
            }}
          >
            Click to expand
          </Badge>
        ) : (
          <Badge
            variant="warning"
            style={{
              fontSize: ".75rem",
              float: "right",
            }}
          >
            Click to collapse
          </Badge>
        )}
      </h4>
      {!pages.length ? (
        <h5 style={{ textAlign: "center" }}>
          There are no pages available. Click below to add a new page.
        </h5>
      ) : (
        <PageComponents collapsed={collapsed} />
      )}
      <Button
        onClick={addPage}
        variant="success"
        size="sm"
        style={{ display: collapsed ? "none" : "block", margin: ".25rem auto" }}
      >
        + Add New Page
      </Button>
    </>
  );
};

export default PageEditor;
