import { useContext, useEffect, useState } from "react";
import globalContext from "../contexts/globalContext";
import {
  PlayFill,
  PauseFill,
  CaretLeftFill,
  CaretRightFill,
  SkipStartFill,
  SkipBackwardFill,
  SkipForwardFill,
  SkipEndFill,
  Shuffle,
} from "react-bootstrap-icons";

const PlayerControls = ({ nextBkg, prevBkg }) => {
  const { bkgSettings, setBkgSettings } = useContext(globalContext);
  let { selected, shuffle, playInterval, defaultPlayInterval } = bkgSettings;

  const togglePlay = () => {
    setBkgSettings({
      ...bkgSettings,
      playInterval:
        playInterval === defaultPlayInterval ? null : defaultPlayInterval,
    });
  };

  return (
    <div className="playerControls">
      <h5>Slideshow Controls</h5>
      {` ${selected + 1} of 23`}
      <button
        onClick={(e) =>
          setBkgSettings({ ...bkgSettings, selected: 0, playInterval: null })
        }
        className="playerControlButton ripple"
      >
        <SkipStartFill />
      </button>
      <button onClick={(e) => prevBkg()} className="playerControlButton ripple">
        <SkipBackwardFill />
      </button>
      <button onClick={togglePlay} className="playerControlButton ripple">
        {playInterval === defaultPlayInterval ? <PauseFill /> : <PlayFill />}
      </button>
      <button onClick={(e) => nextBkg()} className="playerControlButton ripple">
        <SkipForwardFill />
      </button>
      <button
        onClick={(e) =>
          setBkgSettings({ ...bkgSettings, selected: 22, playInterval: null })
        }
        className="playerControlButton ripple"
      >
        <SkipEndFill />
      </button>
      <button
        onClick={() => setBkgSettings({ ...bkgSettings, shuffle: !shuffle })}
        style={{
          backgroundColor: shuffle ? "#0c4b8c" : "#ccc",
        }}
        className="playerControlButton ripple"
      >
        <Shuffle style={{ color: shuffle ? "#fff" : "#0c4b8c" }} />
      </button>
      <input
        onChange={(e) =>
          setBkgSettings({
            ...bkgSettings,
            playInterval: e.target.value * 1000,
            defaultPlayInterval: e.target.value * 1000,
          })
        }
        type="number"
        style={{ width: "3rem" }}
        defaultValue={playInterval / 1000}
      />
      <label>Seconds</label>
    </div>
  );
};

export default PlayerControls;
