import { useRef, useEffect, useContext, useState } from "react";
import globalContext from "../contexts/globalContext";
import Toggle from "./Toggle";
import axios from "axios";
import parseErrors from "../helpers/parseErrors";
import InputMask from "react-input-mask";
import Loading from "./Loading";
import findQuestion from "../helpers/findQuestion";
import findAnswer from "../helpers/findAnswer";

const QA = ({ num, question, active, divider }) => {
  const {
    user,
    setUser,
    addMessage,
    currentQuestion,
    setCurrentQuestion,
    pages,
  } = useContext(globalContext);
  const [loading, setLoading] = useState(true);
  const [answerIndex, setAnswerIndex] = useState(null);
  const [hidden, setHidden] = useState(false);

  const defaultRef = useRef(null);
  const { _id, options, defaultAnswer, required } = question;
  const email = user.email;

  const editAnswer = (e) => {
    let { name, value } = e.target;

    axios
      .post("/editAnswer", {
        value: { qID: name, answer: value },
        currentQuestion,
        email,
      })
      .then((response) => {
        //console.log("Got the new user back", response.data);
        setUser(response.data);
        return response.data;
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 504 || err.response.status === 502) {
          console.log("Request timed out.");
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          parseErrors(err).then((msg) => addMessage(msg, "err"));
        }
      });
  };

  const findAnswerIndex = () => {
    return new Promise((res, rej) => {
      let answers = user.answers;
      let found;

      if (!answers.length || answers.length < 1)
        rej({ msg: "No answer saved." });

      answers.map((answer, index) =>
        answer.qID == _id ? (found = index) : null
      );

      if (found || found === 0) {
        res(found);
      } else {
        console.log("No answer saved");
        rej({ msg: "No answer saved." });
      }
    });
  };

  useEffect(() => {
    if (question?.question === "NHA Development/Site")
      findQuestion(question?.reliesOn, pages[2].questions)
        .then((qs) => findAnswer(qs._id, user.answers))
        .then((answer) => {
          //console.log(answer);
          if (answer === "Public Housing") {
            setHidden(false);
          } else {
            setHidden(true);
            if (user?.answers[answerIndex]?.answer !== "Section 8")
              editAnswer({
                target: { name: _id, value: "Section 8" },
              });
          }
        });
  }, [user, loading]);

  useEffect(() => {
    findAnswerIndex()
      .then((index) => {
        setAnswerIndex(index);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.msg === "No answer saved.") {
          console.log("No answer saved so save a new one...");
          let index = user.answers.length;
          setUser({
            ...user,
            answers: [
              ...user.answers,
              { qID: _id, answer: defaultAnswer ?? "" },
            ],
          });
          setAnswerIndex(index - 1);
        }
      });
  }, [user]);

  useEffect(() => {
    if (active && !loading) defaultRef?.current?.focus();
  }, [loading]);

  const getQtype = () => {
    switch (question.qType) {
      case "Phone":
        return (
          <InputMask
            style={required ? { borderLeft: "2px solid red" } : null}
            onFocus={() => setCurrentQuestion(num)}
            key={String(_id)}
            onFocus={() => setCurrentQuestion(num)}
            defaultValue={user?.answers[answerIndex]?.answer}
            onChange={(e) => editAnswer(e)}
            ref={active ? defaultRef : null}
            name={_id}
            mask="+1 (999) 999-9999"
            maskChar=" "
          />
        );
        break;
      case "Number":
        return (
          <InputMask
            style={required ? { borderLeft: "2px solid red" } : null}
            onFocus={() => setCurrentQuestion(num)}
            key={String(_id)}
            defaultValue={user?.answers[answerIndex]?.answer}
            onChange={(e) => editAnswer(e)}
            ref={active ? defaultRef : null}
            name={_id}
            mask="9999999999"
            maskChar=" "
          />
        );
        break;
      case "Dropdown":
        return (
          <select
            style={required ? { borderLeft: "2px solid red" } : null}
            onFocus={() => setCurrentQuestion(num)}
            name={_id}
            key={String(_id)}
            value={user?.answers[answerIndex]?.answer}
            onChange={(e) => editAnswer(e)}
            ref={active ? defaultRef : null}
          >
            {!defaultAnswer && !user?.answers[answerIndex]?.answer ? (
              <option disabled selected value="">
                -- select an option --
              </option>
            ) : (
              <></>
            )}
            {options.split(",").map((option, index) => (
              <option value={option.trim()} key={String(_id) + index}>
                {option.trim()}
              </option>
            ))}
          </select>
        );
        break;
      case "Text Area":
        return (
          <textarea
            style={required ? { borderLeft: "2px solid red" } : null}
            onFocus={() => setCurrentQuestion(num)}
            key={String(_id)}
            defaultValue={user?.answers[answerIndex]?.answer}
            onChange={(e) => editAnswer(e)}
            name={_id}
            ref={active ? defaultRef : null}
            rows="10"
            cols="50"
          />
        );
        break;
      case "Toggle":
        return (
          <Toggle
            style={required ? { borderLeft: "2px solid red" } : null}
            onFocus={() => setCurrentQuestion(num)}
            key={String(_id)}
            name={_id}
            on={user.answers[answerIndex].answer}
            caption={question.question}
            handleTgl={() =>
              editAnswer({
                target: { name: _id, value: !user.answers[answerIndex].answer },
              })
            }
          />
        );
        break;
      default:
        return (
          <input
            style={required ? { borderLeft: "2px solid red" } : null}
            key={String(_id)}
            onFocus={() => setCurrentQuestion(num)}
            defaultValue={user.answers[answerIndex].answer}
            onChange={(e) => editAnswer(e)}
            name={_id}
            ref={active ? defaultRef : null}
          />
        );
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <div
        className="qa paper"
        style={{ display: hidden ? "none" : "initial" }}
      >
        <h5>{`${question.question}`}</h5>
        {!loading ? getQtype() : <p>Hold up, wait a minute...</p>}
      </div>
      {divider ? <div className="divider">&nbsp;</div> : <></>}
    </>
  );
};

export default QA;
