import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Toggle from "../components/Toggle";
import Button from "react-bootstrap/Button";
import axios from "axios";
import * as checkEmail from "email-validator";
import parseErrors from "../helpers/parseErrors";

import { useState, useEffect, useRef, useContext } from "react";
import globalContext from "../contexts/globalContext";
import Draggable from "react-draggable";
import "../style/login.css";
import Loading from "../components/Loading";

const emailValid = checkEmail.validate;

const Register = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [regLoading, setRegLoading] = useState(false);

  const { registerSuccess } = props;

  const { addMessage, regVisible, setRegVisible } = useContext(globalContext);

  const defaultRef = useRef(null);

  const handleTgl = (name, on) => {
    setShowPassword(on);
  };

  const handleReg = (e) => {
    e.preventDefault();
    if (!emailValid(email)) {
      return addMessage("Email address is not valid.", "err");
    }
    if (password.length < 7) {
      return addMessage("Password must be at least 7 characters.", "err");
    }
    if (password !== password2) {
      return addMessage("Passwords must match.", "err");
    }
    if (email === password) {
      return addMessage("You cannot use your email as your password.", "err");
    }
    setRegLoading(true);

    axios
      .post("/register", { email, password })
      .then(() => {
        addMessage(
          `Thank you for registering.  To complete registration, click on the link in your email from the NHASF.`,
          "success"
        );
      })
      .then(() => registerSuccess())
      .catch((err) => {
        setRegLoading(false);
        parseErrors(err).then((msg) => addMessage(msg, "err"));
      });
  };

  useEffect(() => {
    if (regVisible) defaultRef.current.focus();
  }, []);

  if (!regVisible) return <></>;
  return (
    <Draggable disabled={window.innerWidth < 780}>
      <form className="winForm paper" style={{ animation: "fadeIn 1s" }}>
        <div className="winFormTitle paper handle">
          Register
          <button
            className="closeBtn circleButton paper"
            onClick={() => setRegVisible(false)}
            type="button"
          >
            &nbsp;
          </button>
        </div>

        <div className="winFormContent">
          <h5 style={{ margin: "1rem" }}>
            Register for an NHASF account here. If you already have an account,
            click "Login" instead.
          </h5>
          <Row>
            <Col md={3}>
              <input
                style={{ textTransform: "lowercase" }}
                placeholder="Email"
                ref={defaultRef}
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
            </Col>
            <Col md={3}>
              <input
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                defaultValue={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Col>
            <Col md={3}>
              <input
                placeholder="Confirm Password"
                type={showPassword ? "text" : "password"}
                defaultValue={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
            </Col>
            <Col md={3} className="vCenter">
              <Toggle
                name={"showPassword"}
                on={showPassword}
                caption="Show Password"
                handleTgl={handleTgl}
              />
            </Col>
          </Row>
        </div>
        <Button type="submit" disabled={regLoading} onClick={handleReg}>
          {regLoading ? <Loading /> : "Register"}
        </Button>
      </form>
    </Draggable>
  );
};

export default Register;
