const getDateStrings = (dateString) => {
  return new Promise(async (resolve, reject) => {
    let date = new Date(dateString);
    let month = "";
    switch (date.getMonth()) {
      case 0:
        month = "January";
        break;
      case 1:
        month = "February";
        break;
      case 2:
        month = "March";
        break;
      case 3:
        month = "April";
        break;
      case 4:
        month = "May";
        break;
      case 5:
        month = "June";
        break;
      case 6:
        month = "July";
        break;
      case 7:
        month = "August";
        break;
      case 8:
        month = "September";
        break;
      case 9:
        month = "October";
        break;
      case 10:
        month = "November";
        break;
      case 11:
        month = "December";
        break;
      default:
        month = "";
    }
    if (month === "" || !month) {
      return resolve("January 1, 1900");
    } else {
      processDate(month, date).then((finalDate) => {
        // console.log("Sending back fianlDate", finalDate);
        if (finalDate === "January 1, 1995") {
          resolve("Never");
        } else {
          resolve(finalDate);
        }
      });
    }
  });
};

let processDate = (month, date) => {
  return new Promise(async (resolve, reject) => {
    let finalDate = await `${month} ${date
      .getDate()
      .toString()}, ${date.getFullYear().toString()}`;
    //console.log(finalDate);
    resolve(finalDate);
  });
};

export default getDateStrings;
