import Axios from "axios";

import handleLogout from "./handleLogout";
import parseErrors from "../helpers/parseErrors";
let timerStarted;

let loginTimer;

let startTimer = () => {
  loginTimer = setTimeout(() => {
    console.log("Timeout passed");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("jwt");
    handleLogout();
  }, 1800000);
};

let updateToken = (email, redirect) => {
  if (!email) return { msg: "User is not logged in." };

  let rememberMe = localStorage.getItem("rememberMe") === "true" ? true : false;
  clearTimeout(loginTimer);
  timerStarted = false;

  //console.log("Requesting token");
  return Axios.post("/updateToken", {
    email,
    rememberMe,
  })
    .then((response) => {
      let token = response.data.token;
      // console.log(token);
      localStorage.setItem("jwt", token);

      // console.log("starting new timer");
      timerStarted = true;
      if (localStorage.getItem("rememberMe") === "false") startTimer();
      //console.log("token valid");
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 504 || err.response.status === 502) {
        console.log("Request timed out.");
        // console.log(err.response.data);
        //console.log(err.response.status);
        // console.log(err.response.headers);
      } else handleLogout();
      console.log(err);

      if (localStorage.getItem("jwt")) {
        parseErrors(err).then((msg) => console.log(msg));
      }
      localStorage.removeItem("email");
      localStorage.removeItem("jwt");
      window.location = "/";
      return false;
    });
};

//1800000 30 minutes
export default updateToken;
