import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useEffect, useRef, useContext } from "react";
import globalContext from "../contexts/globalContext";
import Toggle from "./Toggle";
import "../style/login.css";
import axios from "axios";
import parseErrors from "../helpers/parseErrors";
import Draggable from "react-draggable";
import { useHistory } from "react-router-dom";

const ResetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  let { email, resetCode } = props;
  let history = useHistory();

  const { addMessage, loginVisible, setLoginVisible } = useContext(
    globalContext
  );

  const defaultRef = useRef(null);

  const handleTgl = (name, on) => {
    setShowPassword(on);
  };

  const handleFinishPasswordReset = (e) => {
    e.preventDefault();
    console.log(props);
    axios
      .post("/finishPasswordReset", { email, resetCode, password, password2 })
      .then(() => {
        history.push("/");
        addMessage("You have successfully changed your password!", "success");
      })
      .catch((err) => parseErrors(err).then((msg) => addMessage(msg, "err")));
  };

  return (
    <Draggable disabled={window.innerWidth < 780}>
      <form className="winForm paper" style={{ animation: "fadeIn 1s" }}>
        <div className="winFormTitle paper handle">
          Change Password
          <button
            className="closeBtn circleButton paper"
            onClick={() => setLoginVisible(false)}
            type="button"
          >
            &nbsp;
          </button>
        </div>
        <Container>
          <div className="winFormContent">
            <Row>
              <Col xs={12}>
                <input
                  placeholder="New Password"
                  type={showPassword ? "text" : "password"}
                  defaultValue={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></input>
              </Col>

              <Col xs={12}>
                <input
                  placeholder="Repeat New Password"
                  type={showPassword ? "text" : "password"}
                  defaultValue={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                ></input>
              </Col>
              <Col className="vCenter">
                <Toggle
                  name={"showPassword"}
                  on={showPassword}
                  caption="Show Password"
                  handleTgl={handleTgl}
                />
              </Col>
            </Row>
          </div>
        </Container>
        <Button
          style={{ margin: "1rem" }}
          type="submit"
          onClick={handleFinishPasswordReset}
        >
          Change Password
        </Button>
      </form>
    </Draggable>
  );
};

export default ResetPassword;
