import { useState, useRef, useContext, useEffect } from "react";
import globalContext from "../contexts/globalContext";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Toggle from "../components/Toggle";
import Upload from "../components/Upload";
import { FileEarmarkArrowDown } from "react-bootstrap-icons";
import Download from "./Download";
import axios from "axios";
import parseErrors from "../helpers/parseErrors";
import Loading from "./Loading";

const SingleCollege = ({
  college,
  index,
  editCollege,
  removeCollege,
  handleDeleteLetter,
  active,
}) => {
  let { name, accepted, letter, _id } = college;
  const [beginDownload, setBeginDownload] = useState(false);
  const { user, saveUser, setCurrentQuestion } = useContext(globalContext);
  const [loading, setLoading] = useState(true);

  let originalFileName;

  const handleFinishedUpload = async (doc) => {
    let tempColleges = await user.colleges.slice();
    //console.log(index);
    tempColleges[index].letter = doc;
    saveUser({ colleges: tempColleges });
  };

  const defaultRef = useRef(null);

  useEffect(() => {
    if (active) defaultRef?.current?.focus();
    originalFileName = college?.letter;
    setLoading(false);
  }, []);

  if (loading) return <Loading />;

  return (
    <Col md={12} lg={6} xl={4}>
      <div className="college paper">
        <button
          className="closeBtn circleButton paper smTopBtn"
          type="button"
          onClick={() => removeCollege(index)}
        >
          &nbsp;
        </button>
        <Row>
          <Col xs={accepted ? 7 : 12} style={{ padding: 0, transition: "0s" }}>
            <Col>
              <input
                onFocus={() => setCurrentQuestion(index)}
                style={{ width: "95%" }}
                name={"name" + _id}
                defaultValue={name}
                ref={active ? defaultRef : null}
                onChange={(e) => editCollege({ name: e.target.value }, index)}
                placeholder="College Name"
              />
            </Col>
            <Col>
              <Toggle
                style={{
                  borderLeft: "2px solid red",
                  margin: ".2rem .5rem",
                }}
                //onFocus={() => setCurrentQuestion(num)}
                name={"toggle" + _id}
                on={accepted}
                caption={"Accepted?"}
                options="Yes|No"
                handleTgl={() => editCollege({ accepted: !accepted }, index)}
              />
            </Col>
          </Col>
          <Col
            xs={5}
            style={{ display: accepted ? "flex" : "none", padding: 0 }}
          >
            {!letter ? (
              <div className="collegeRight">
                <Upload
                  name={"upload" + _id}
                  caption={"Select Acceptance Letter"}
                  email={user.email}
                  uploadPath={"/uploadDocument"}
                  type="college"
                  collegeId={_id}
                  docType="college"
                  onSuccess={handleFinishedUpload}
                />
              </div>
            ) : (
              <div className="collegeRight">
                {beginDownload ? (
                  <Loading caption="Downloading..." />
                ) : (
                  <FileEarmarkArrowDown
                    onClick={() => setBeginDownload(true)}
                    style={{ fontSize: "2rem", margin: 0 }}
                  />
                )}
                <p style={{ fontSize: ".75rem", margin: 0 }}>
                  {letter.originalFileName.length > 30
                    ? letter.originalFileName.substring(0, 30) + "..."
                    : letter.originalFileName}
                </p>
                <div>
                  <Button
                    style={{ padding: 0 }}
                    size="sm"
                    variant="danger"
                    onClick={() => handleDeleteLetter(letter.fileName, index)}
                  >
                    Delete
                  </Button>
                  {/* <Button
                    style={{ padding: 0 }}
                    size="sm"
                    onClick={() => setBeginDownload(true)}
                  >
                    Download
                  </Button> */}
                </div>
                <Download
                  email={user.email}
                  pathId={user.pathId}
                  doc={letter}
                  beginDownload={beginDownload}
                  setBeginDownload={setBeginDownload}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Col>
  );
};

const Colleges = () => {
  const {
    user,
    saveUser,
    addMessage,
    currentQuestion,
    setCurrentQuestion,
  } = useContext(globalContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) setLoading(false);
    // console.log(user);
  }, [user]);

  const addCollege = () =>
    saveUser({ colleges: [...(user.colleges || []), {}] }).then((usr) => {
      //console.log(usr);
      setCurrentQuestion(usr.colleges.length - 1);
    });

  const editCollege = async (newCollege, index) => {
    let tempColleges = await user.colleges.slice();
    tempColleges[index] = { ...tempColleges[index], ...newCollege };
    saveUser({ colleges: tempColleges });
  };

  const handleDeleteLetter = (fileName, index, dontSave) => {
    //console.log("delete", fileName);
    axios
      .post("/deleteDocument", {
        fileName,
        pathId: user.pathId,
        email: user.email,
      })
      .then(async () => {
        let tempColleges = await user.colleges.slice();
        tempColleges[index].letter = null;
        if (dontSave) {
          return tempColleges;
        }
        await saveUser({ colleges: tempColleges });
        addMessage("Successfully removed document.", "success");
        addMessage(
          "Note: If you have been accepted to a college, you must uplaod an acceptance letter to complete your application.",
          "warning"
        );
      })
      .catch((err) => {
        console.log(err);
        parseErrors(err).then((msg) => addMessage(msg, "err"));
      });
  };

  const removeCollege = async (index) => {
    // console.log("Removing index", index, user.colleges[index]);
    // console.log(user.colleges);
    if (user.colleges[index]?.letter) {
      let fileName = user.colleges[index].letter.fileName;
      handleDeleteLetter(fileName, index, true);
    }
    saveUser({
      colleges: user.colleges.slice().filter((college, i) => i !== index),
    });
  };

  if (loading) return <Loading />;
  // console.log(user.colleges);
  return (
    <Container style={{ height: "100%" }}>
      <Row className="collegeRow">
        {user?.colleges ? (
          user.colleges.map((college, index) => {
            return (
              <SingleCollege
                key={college._id}
                college={college}
                index={index}
                editCollege={editCollege}
                removeCollege={removeCollege}
                active={currentQuestion === index}
                handleDeleteLetter={handleDeleteLetter}
              />
            );
          })
        ) : (
          <Col>
            <h5 style={{ fontSize: "20rem", color: "green" }}>
              No colleges listed
            </h5>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12}>
          <Button
            style={{ display: "block", margin: "1rem auto" }}
            onClick={addCollege}
          >
            Add College
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Colleges;
