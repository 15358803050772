import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import globalContext from "../contexts/globalContext";

const PageNotFound = () => {
  const { addMessage } = useContext(globalContext);
  const history = useHistory();

  useEffect(() => {
    history.push("/");
  }, []);

  return <></>;
};

export default PageNotFound;
