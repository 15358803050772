import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";

import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FileEarmarkArrowUp } from "react-bootstrap-icons";

import Loading from "../components/Loading";
import globalContext from "../contexts/globalContext";
import parseErrors from "../helpers/parseErrors";

import "../style/files.css";

const acceptedMimeTypes = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "image/png",
  "application/x-iwork-pages-sffpages",
  "application/rtf",
  "application/msword",
  "text/plain",
];

// const Button = (props) => {
//   let { name, onClick, caption } = props;
//   return (
//     <button className="customButton" name={name} onClick={onClick}>
//       {caption}
//     </button>
//   );
// };

const Upload = (props) => {
  let { user, addMessage } = useContext(globalContext);
  const [preview, setPreview] = useState(null);
  let [file, setFile] = useState(null);
  let [caption, setCaption] = useState(null);
  let [loading, setLoading] = useState(true);
  let [uploading, setUploading] = useState(false);
  let [progress, setProgress] = useState(0);

  useEffect(() => {
    if (user.email) {
      setCaption(props.caption);
      setLoading(false);
    }
  }, [props]);

  const fileChange = (e) => {
    //console.log(e.target.files[0]);
    const selected = e.target.files[0];

    Promise.all([checkFileType(selected), checkFileSize(selected)])
      .then(() => {
        setFile(selected);
        setPreview(URL.createObjectURL(selected));
      })
      .catch((err) => {
        console.log(err);
        setFile(null);
        parseErrors(err).then((msg) => addMessage(msg, "err"));
      });
  };

  const fileUpload = (e) => {
    //prettier-ignore
    const colors = [ "red", "#DC3545", "#C84245", "#B44E45", "#A05B45", "#8C6845", "#787445", "#648145", "#508E45", "#3C9A45", "#28A745", ];
    if (e) e.preventDefault();
    if (!file) {
      addMessage("Please select file first before uploading.", "err");
      return;
    }
    Promise.all([checkFileType(file), checkFileSize(file)])
      .then(async () => {
        //console.log("checked file size and type, ok");
        setUploading(true);
        const formData = new FormData();
        formData.append("file", file);

        if (user.email) formData.append("email", user.email);
        if (user) formData.append("user", JSON.stringify(user));
        if (props.collegeId) formData.append("collegeId", props.collegeId);
        if (props.docType) formData.append("docType", props.docType);

        return Axios.post(props.uploadPath, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          redirect: "manual",
          onUploadProgress: (progress) => {
            if (e && e.total && e.loaded) {
              setProgress(Math.floor((e.loaded / e.total) * 100));
              document.querySelector(
                ".progress-bar"
              ).style.backgroundColor = `${
                colors[Math.floor((e.loaded / e.total) * 10)]
              }`;
            } else {
              setProgress(progress + 1);
              document.querySelector(
                ".progress-bar"
              ).style.backgroundColor = `${progress}`;
            }
          },
        });
      })
      .then((response) => {
        resetFileUpload();
        addMessage("Successfully uploaded file.", "success");
        //console.log(response);
        return response.data;
      })
      .then((data) => {
        setUploading(false);
        setFile(null);
        props.onSuccess(data);
      })
      .catch((err) => {
        setUploading(false);
        console.log(err);
        setFile(null);
        parseErrors(err).then((msg) => addMessage(msg, "err"));
      });
  };

  const resetFileUpload = () => {
    setFile(null);
    setProgress(0);
    setPreview(null);
    setUploading(false);
  };

  const checkFileType = (file) => {
    //console.log("Checking file type");
    return new Promise(async (res, rej) => {
      if (!file) {
        console.log("no file");
        rej({ msg: "Please select a document file." });
      }

      if (!acceptedMimeTypes.find((element) => element === file.type)) {
        rej({
          msg:
            "You can only upload one of the following file types: pdf, doc, docx, pages",
        });
      } else {
        res("success");
      }
    });
  };

  const checkFileSize = (file) => {
    return new Promise((res, rej) => {
      if (file.size > 20000000) {
        rej({ msg: "File cannot be larger than 20Mb." });
      } else if (!file.size || file.size < 1000) {
        rej({ msg: "File is invalid." });
      } else {
        res({ msg: "success" });
      }
    });
  };

  if (!loading) {
    return (
      <>
        <div className="uploadIcon">
          <input
            id={props.name || "videoFileUpload"}
            type="file"
            name="fileUpload"
            className="fileUpload"
            onChange={fileChange}
          />
          <label
            style={{
              border: "none",
              padding: 0,
              margin: 0,
              display: file ? "none" : "flex",
            }}
            className="fileUploadButton"
            htmlFor={props.name || "videoFileUpload"}
          >
            <FileEarmarkArrowUp style={{ fontSize: "2rem", margin: 0 }} />
            <p
              style={{
                display: file && file.name ? "none" : "flex",
                fontSize: ".75rem",
                margin: 0,
                ...props?.style,
              }}
            >
              {caption || "Choose a file"}
            </p>
          </label>

          {file && uploading ? <Loading msg={"Uploading file..."} /> : null}
          {file && file.name ? (
            // <img
            //   id={Date.now()}
            //   style={{ height: "3rem" }}
            //   className="filePreview"
            //   src={preview}
            // />
            <p style={{ fontSize: ".75rem" }}>
              {file.name.length > 30
                ? file.name.substring(0, 30) + "..."
                : file.name}
            </p>
          ) : null}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              disabled={!file || !file.name || uploading}
              onClick={resetFileUpload}
              caption="Cancel"
              style={{ padding: 0 }}
              size="sm"
            >
              Cancel
            </Button>
            <Button
              disabled={!file || !file.name || uploading}
              onClick={fileUpload}
              style={{ padding: 0 }}
              size="sm"
              caption="Upload"
            >
              Upload
            </Button>
          </div>
          <ProgressBar
            style={{
              margin: ".5rem 0",
              display: progress !== 0 || uploading ? "flex" : "none",
              width: "75%",
            }}
            now={progress}
            label={`${Math.floor(progress)}%`}
          />
        </div>
      </>
    );
  } else {
    return null;
  }
};
export default Upload;
