import { useEffect, useState } from "react";
import CustomToast from "./CustomToast";
import removeMessage from "../helpers/removeMessage";

const ToastHolder = ({ messages }) => {
  let [customToasts, setCustomToasts] = useState([]);

  useEffect(() => {
    if (messages?.length > 0) getMessages();
  }, [messages]);

  const getMessages = () => {
    for (var i = 0; i < messages.length; i++) {
      let { msg, title, dateCreated, type, id } = messages[i];
      let newToast = (
        <CustomToast
          id={id}
          key={"message" + i + Date.now()}
          index={i}
          type={type}
          title={title}
          message={msg}
          dateCreated={dateCreated}
          removeMessage={removeMessage}
        />
      );

      setCustomToasts([...customToasts, newToast]);
    }
  };

  return <div className="toastHolder">{customToasts}</div>;
};

export default ToastHolder;
