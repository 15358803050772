import axios from "axios";
import { useState, useEffect, useContext } from "react";
import globalContext from "../contexts/globalContext";
import Loading from "./Loading";
import parseErrors from "../helpers/parseErrors";
import Toggle from "../components/Toggle";
import getDateString from "../helpers/getDateStrings";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Badge from "react-bootstrap/Badge";
import {
  FileEarmarkArrowDown,
  CaretUpFill,
  CaretDownFill,
} from "react-bootstrap-icons";
import Download from "./Download";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import getProgress from "../helpers/getProgress";
import { useHistory } from "react-router-dom";
import addDocsToPages from "../helpers/addDocsToPages";
import uuid from "react-uuid";

const SingleUserDocuments = ({ user, pathId, startDownload, downloading }) => {
  // prettier-ignore
  let { lease, transcript, sat, fasfa, essay, recommendation1, recommendation2, _id, } = user;

  let documents = [
    { name: "lease", ...lease },
    { name: "transcript", ...transcript },
    { name: "sat", ...sat },
    { name: "fasfa", ...fasfa },
    { name: "essay", ...essay },
    { name: "recommendation1", ...recommendation1 },
    { name: "recommendation2", ...recommendation2 },
  ];

  return documents.map((doc, index) => {
    let { name, fileName, originalFileName } = doc;
    return (
      <Col md={4} key={"doc" + _id + index}>
        <div className="adminQA adminQABoxContent">
          <span
            style={{
              color: "#0c4b8c",
              fontWeight: "bold",
              width: "100%",
              backgroundColor: "#fff",
              borderBottom: "1px solid",
            }}
          >{`${name}`}</span>
          {fileName ? (
            <>
              {downloading ? (
                <Loading caption="Downloading..." />
              ) : (
                <FileEarmarkArrowDown
                  onClick={() => startDownload(doc)}
                  style={{ fontSize: "2rem", margin: 0 }}
                />
              )}
              <p>
                {originalFileName.length > 30
                  ? originalFileName.substring(0, 30) + "..."
                  : originalFileName}
              </p>
            </>
          ) : (
            <></>
          )}
          {!fileName ? (
            <span style={{ color: "red", marginBottom: "2rem" }}>
              Document has not been uploaded yet.
            </span>
          ) : (
            <></>
          )}
        </div>
      </Col>
    );
  });
};

const SingleUserColleges = ({
  colleges,
  pathId,
  startDownload,
  downloading,
}) => {
  return colleges.map((college) => {
    let { accepted, name, _id, letter } = college;
    return (
      <Col md={4} key={uuid()}>
        <div className="adminQA adminQABoxContent">
          <span
            style={{
              color: "#0c4b8c",
              fontWeight: "bold",
              width: "100%",
              backgroundColor: "#fff",
              borderBottom: "1px solid",
            }}
          >{`${name}`}</span>
          <span>{`Accepted: ${accepted ? "Yes" : "No"}`}</span>
          {accepted && letter ? (
            <>
              {downloading ? (
                <Loading caption="Downloading..." />
              ) : (
                <FileEarmarkArrowDown
                  onClick={() => startDownload(letter)}
                  style={{ fontSize: "2rem", margin: 0 }}
                />
              )}
              <p>
                {letter.originalFileName.length > 30
                  ? letter.originalFileName.substring(0, 30) + "..."
                  : letter.originalFileName}
              </p>
            </>
          ) : (
            <></>
          )}
          {accepted && !letter ? (
            <span style={{ color: "red" }}>missing acceptance letter</span>
          ) : (
            <></>
          )}
        </div>
      </Col>
    );
  });
};

const SingleQuestion = ({ qs, answers }) => {
  const [answer, setAnswer] = useState(null);

  const getAnswer = async () => {
    let tempAnswer;
    try {
      tempAnswer = await answers.find((ans) => ans.qID == qs._id);
      if (tempAnswer?.answer && typeof tempAnswer?.answer !== "object")
        setAnswer(tempAnswer.answer);
      if (!tempAnswer?.answer) setAnswer("blank");
    } catch (err) {
      console.log(err, qs, tempAnswer);
    }
  };

  useEffect(() => {
    if (!qs.special) getAnswer();
  }, [qs]);

  if (!answer) return <Loading />;
  if (qs.qType === "Text Area")
    return (
      <div className="adminQAtext">
        <span
          style={{ color: "#0c4b8c", fontWeight: "bold" }}
        >{`${qs.question}: `}</span>
        <textarea rows="8" cols="50" defaultValue={answer} readOnly></textarea>
      </div>
    );

  return (
    <div className="adminQA">
      <span
        style={{ color: "#0c4b8c", fontWeight: "bold" }}
      >{`${qs.question}: `}</span>
      <span
        style={{ color: answer === "blank" && qs.required ? "red" : "initial" }}
      >
        {answer}
      </span>{" "}
    </div>
  );
};

const SingleUser = ({
  usr,
  handleEditUser,
  showAdvanced,
  setShowAdvanced,
  getUserStats,
}) => {
  let { _id, email, colleges, answers, activated, dateLastLogin } = usr;
  const [collapsed, setCollapsed] = useState(true);
  const {
    user,
    setUser,
    pages,
    setPrintUser,
    dialog,
    setDialog,
    addMessage,
    users,
    setUsers,
  } = useContext(globalContext);
  const [dateString, setDateString] = useState("Never Logged In");
  const [timeString, setTimeString] = useState("");
  const [beginDownload, setBeginDownload] = useState(false);
  const [doc, setDoc] = useState(null);
  const [required, setRequired] = useState(0);
  const [answered, setAnswered] = useState(0);
  const [percent, setPercent] = useState(0);
  const [progressVariant, setProgressVariant] = useState("primary");
  const [loading, setLoading] = useState(true);
  const [finalAnswers, setFinalAnswers] = useState([]);

  const startDownload = (doc) => {
    setDoc(doc);
    setBeginDownload(true);
  };

  const handlePromote = () => {
    setDialog({
      show: true,
      title:
        usr.accountLevel === 0
          ? "Promote to admin?"
          : "Demote admin to normal user?",
      message:
        usr.accountLevel === 0
          ? `Are you sure you want to PROMOTE "${usr.email}" to an administrator?`
          : `Are you sure you want to DEMOTE "${usr.email}" from an administrator?`,
      handleClose: setDialog({}),
      handleConfirm: handlePerformPromote,
    });
  };

  const handlePerformPromote = () => {
    setDialog({});
    setShowAdvanced(false);
    handleEditUser(usr._id, { accountLevel: usr.accountLevel === 0 ? 1 : 0 });
  };

  const handleDelete = () => {
    setDialog({
      show: true,
      title: "Delete user?!?",
      message: `Are you sure you want to delete the user "${usr.email}?"  There is no option to undo.`,
      handleConfirm: handlePerformDelete,
      handleClose: () => setDialog({}),
    });
  };

  const handlePerformDelete = () => {
    setDialog({});
    setShowAdvanced(false);
    axios
      .post("/adminDeleteUser", {
        _id: usr._id,
        email: user.email,
        accountLevel: user.accountLevel,
      })
      .then((response) => {
        setUsers(response.data);

        //force a state update so I can trigger the user dependancy that causes users to resort
        setUser({ ...user });
        addMessage(`Successfully deleted ${usr.email}`, "success");
      })
      .catch((err) => parseErrors(err).then((msg) => addMessage(msg, "err")));
  };

  const handleEmail = (e) => {
    e.stopPropagation();
    window.open(`mailto:${usr.email}`, "_blank");
  };

  const handlePrint = (e) => {
    e.stopPropagation();
    setPrintUser(finalAnswers);
    const win = window.open(`/pfriendly?email=${usr.email}`);
    win.focus();
    setPrintUser(null);
  };

  useEffect(() => {
    if (!dateString.startsWith("Never"))
      setTimeString(new Date(dateLastLogin).toLocaleTimeString());
  }, [dateString]);

  useEffect(() => {
    if (dateLastLogin) {
      getDateString(dateLastLogin).then((newDate) => setDateString(newDate));
    }

    addDocsToPages(usr, pages)
      .then((newPages) => getProgress(newPages, usr.answers))
      .then((response) => {
        if (response?.required && response?.answered) {
          setRequired(response.required);
          setAnswered(response.answered);

          // handleEditUser(_id, {
          //   complete: (response.answered / response.required) * 100,
          // });

          setUsers(
            users.map((usr) => {
              if (usr._id === _id)
                return {
                  ...usr,
                  complete: (response.answered / response.required) * 100,
                };
              else return usr;
            })
          );

          setFinalAnswers(response.finalAnswers);
        }
      })
      .then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!required && percent) return setPercent(0);
    let perc = (answered / required) * 100;
    setPercent(perc);
    if (perc < 40) setProgressVariant("danger");
    if (perc >= 40 && perc < 70) setProgressVariant("warning");
    if (perc >= 70 && perc < 100) setProgressVariant("info");
    if (perc === 100) setProgressVariant("success");
  }, [required, answered]);

  if (!pages[0] || loading) return <Loading />;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {`${Math.floor(percent)}% Complete`}
    </Tooltip>
  );

  return (
    <div className="college paper userManagement">
      <div
        className="titleBar"
        style={{
          backgroundColor: collapsed ? "#ddd" : "rgba(12, 76, 140, .5)",
        }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <div className="progressHolder">
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
            complete={percent}
          >
            <ProgressBar
              variant={progressVariant}
              style={percent < 1 ? { backgroundColor: "darkred" } : {}}
              now={percent}
              label={
                answers[0]?.answer && answers[2]?.answer ? (
                  `${answers[0].answer} ${answers[2].answer}`
                ) : (
                  <>{`${email}`}</>
                )
              }
            />
          </OverlayTrigger>
        </div>

        <p>
          <span style={{ color: "#0c4b8c", fontWeight: "bold" }}>
            Last Login:{" "}
          </span>
          {`${dateString} ${timeString}`}
        </p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button onClick={handleEmail}>Email</Button>
          <Button onClick={handlePrint}>Print</Button>
          <Toggle
            name={_id + "activated"}
            on={activated}
            options={"Yes|No"}
            caption="Activated"
            short={true}
            handleTgl={() => handleEditUser(_id, { activated: !activated })}
          />
        </div>
        {user.accountLevel > 1 && showAdvanced && usr.accountLevel < 2 ? (
          <>
            <Button
              variant="danger"
              style={{ padding: ".25rem" }}
              size="sm"
              onClick={handlePromote}
            >
              {`${usr.accountLevel === 0 ? "Promote" : "Demote"} Admin`}
            </Button>
          </>
        ) : (
          <></>
        )}

        {user.accountLevel > 0 && showAdvanced && usr.accountLevel < 2 ? (
          <Button
            variant="danger"
            style={{ padding: ".25rem" }}
            size="sm"
            onClick={handleDelete}
          >
            Delete
          </Button>
        ) : (
          <></>
        )}
      </div>

      <div
        className="userManagementContent"
        style={{ display: collapsed ? "none" : "block" }}
      >
        <Tabs
          key={"tabs" + usr._id}
          defaultActiveKey={usr._id + String(pages[0]._id)}
          id={"tabs" + _id}
        >
          {pages.map((pg, index) => {
            return (
              <Tab
                key={"pgTab" + index + usr._id + pg._id}
                eventKey={usr._id + String(pg._id)}
                title={pg.pageName}
              >
                <Row>
                  {pg.questions.map((qs, index) => (
                    <Col md={pg.questions.length === 1 ? 12 : 4}>
                      <SingleQuestion
                        key={"qs" + usr._id + index}
                        qs={qs}
                        answers={answers}
                      />
                    </Col>
                  ))}
                </Row>
              </Tab>
            );
          })}
          <Tab eventKey={"colleges" + usr._id} title="Colleges">
            <Row>
              {usr.colleges.length > 0 ? (
                <SingleUserColleges
                  key={"usrColleges" + usr._id}
                  colleges={colleges}
                  pathId={usr.pathId}
                  startDownload={startDownload}
                  downloading={beginDownload}
                />
              ) : (
                <h5 className="hCenter">No colleges listed</h5>
              )}
            </Row>
          </Tab>
          <Tab eventKey={"documents" + usr._id} title="Documents">
            <Row>
              <SingleUserDocuments
                key={"usrDocuments" + usr._id}
                user={usr}
                pathId={usr.pathId}
                startDownload={startDownload}
                downloading={beginDownload}
              />
            </Row>
          </Tab>
        </Tabs>
      </div>
      {doc && beginDownload ? (
        <Download
          email={user.email}
          pathId={usr.pathId}
          doc={doc}
          beginDownload={beginDownload}
          setBeginDownload={setBeginDownload}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

const UserManagement = ({ getUserStats }) => {
  const { user, saveUser, addMessage, users, setUsers } = useContext(
    globalContext
  );
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const sortUsers = (sortBy, localUsers) => {
    let tempUsers = localUsers?.slice() ?? users?.slice();
    //let tempUsers = users; //[...users];
    console.log(user.sortBy, user.ascending, tempUsers);
    if (sortBy) {
      setUsers(() =>
        user.ascending
          ? tempUsers.sort((a, b) => (a[sortBy] > b[sortBy] ? 1 : -1))
          : tempUsers.sort((a, b) => (a[sortBy] < b[sortBy] ? 1 : -1))
      );

      if (loading) {
        setLoading(false);
      }
    }
  };

  const handleEditUser = (_id, update) => {
    let { email, accountLevel } = user;

    axios
      .post("/adminEditUser", { email, accountLevel, _id, update })
      .then((response) => {
        let newUser = response.data;
        setUsers([...users.map((u, i) => (u._id === _id ? newUser : u))]);
      })
      .catch((err) => parseErrors(err).then((msg) => addMessage(msg, "err")));
  };

  useEffect(() => {
    if (!collapsed || !loading) getUserStats();
  }, [collapsed, loading]);

  useEffect(() => {
    sortUsers(user.sortBy, users);
  }, [user]);

  useEffect(() => {
    let history = useHistory;
    if (!user) return history.push("/");
    let { email, accountLevel } = user;
    axios
      .post("/getAllUsers", { email, accountLevel })
      .then((response) => {
        //setUsers(response.data);
        sortUsers(user.sortBy, response.data);
      })
      .catch((err) => parseErrors(err).then((msg) => addMessage(msg, "err")));
  }, []);

  if (loading || !users) return <Loading caption="Loading Users..." />;

  return (
    <>
      <h4
        className="adminPanelSectionLabel"
        onClick={() => setCollapsed(!collapsed)}
      >
        User Management
        {collapsed ? (
          <Badge
            style={{
              fontSize: ".75rem",
              float: "right",
              backgroundColor: "gray",
            }}
          >
            Click to expand
          </Badge>
        ) : (
          <Badge
            variant="warning"
            style={{
              fontSize: ".75rem",
              float: "right",
            }}
          >
            Click to collapse
          </Badge>
        )}
      </h4>
      {!users.length ? (
        <h5
          style={{
            textAlign: "center",
            display: collapsed ? "none" : "initial",
          }}
        >
          There are no users, somehow.
        </h5>
      ) : (
        <div
          className="userManagement"
          style={{ display: collapsed ? "none" : "block" }}
        >
          <div className="allUsersContainer">
            <div className="sortBy">
              <div>
                <Button
                  style={{
                    backgroundColor: user.ascending ? "#0c4bbc" : "#0c4b8c",
                  }}
                  onClick={() => saveUser({ ascending: true })}
                  className="btn sortBtn"
                >
                  <CaretUpFill className="btnIconStyle" />
                </Button>
                <Button
                  style={{
                    backgroundColor: !user.ascending ? "#0c4bbc" : "#0c4b8c",
                  }}
                  onClick={() => saveUser({ ascending: false })}
                  className="btn sortBtn"
                >
                  <CaretDownFill className="btnIconStyle" />
                </Button>
              </div>
              <Button
                style={{
                  backgroundColor:
                    user.sortBy === "dateLastLogin" ? "#0c4bbc" : "#0c4b8c",
                }}
                onClick={() => saveUser({ sortBy: "dateLastLogin" })}
                className="btn sortBtn"
              >
                Sort By Last Login
              </Button>
              <Button
                style={{
                  backgroundColor:
                    user.sortBy === "complete" ? "#0c4bbc" : "#0c4b8c",
                }}
                onClick={() => saveUser({ sortBy: "complete" })}
                className="btn sortBtn"
              >
                Sort By Application Complete
              </Button>
              <Button
                style={{
                  backgroundColor:
                    user.sortBy === "accountLevel" ? "#0c4bbc" : "#0c4b8c",
                }}
                onClick={() => saveUser({ sortBy: "accountLevel" })}
                className="btn sortBtn"
              >
                Sort By Account Level
              </Button>
              <Button
                style={{
                  backgroundColor:
                    user.sortBy === "dateCreated" ? "#0c4bbc" : "#0c4b8c",
                }}
                onClick={() => saveUser({ sortBy: "dateCreated" })}
                className="btn sortBtn"
              >
                Sort By Account Created
              </Button>
            </div>
            {users.map((usr, i) => (
              <SingleUser
                key={"sU" + usr._id + i}
                usr={usr}
                handleEditUser={handleEditUser}
                showAdvanced={showAdvanced}
                setShowAdvanced={setShowAdvanced}
                getUserStats={getUserStats}
              />
            ))}
          </div>
          <div className="legend">
            <h5 style={{ display: "block", margin: "1rem auto" }}>Legend</h5>
            <Container className="legendItemContainer">
              <Row>
                <Col md={3}>
                  <div className="legendItem">
                    <p>{"< 40% Complete:"}</p>
                    <span className="legendItemProgress bg-danger">&nbsp;</span>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="legendItem">
                    <p>{"40% - 69% Complete:"}</p>
                    <span className="legendItemProgress bg-warning">
                      &nbsp;
                    </span>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="legendItem">
                    <p>{"70% - 99% Complete:"}</p>
                    <span className="legendItemProgress bg-info">&nbsp;</span>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="legendItem">
                    <p>{"100% Complete:"}</p>
                    <span className="legendItemProgress bg-success">
                      &nbsp;
                    </span>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {user.accountLevel > 0 ? (
            <div className="legend">
              <h5 style={{ display: "block", margin: "1rem auto" }}>
                Advanced Controls
              </h5>
              <Container className="legendItemContainer">
                <Row>
                  <Col>
                    <Toggle
                      name={"showAdvancedControls"}
                      on={showAdvanced}
                      options={"Yes|No"}
                      caption="Show Advanced Controls"
                      handleTgl={() => setShowAdvanced(!showAdvanced)}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
};

export default UserManagement;
