import { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Login from "../components/Login";
import Register from "../components/Register";
import { useHistory } from "react-router-dom";
import globalContext from "../contexts/globalContext";
import Popup from "../components/Popup";
import ResetPassword from "../components/ResetPassword";
import parseErrors from "../helpers/parseErrors";
import axios from "axios";

const Home = (props) => {
  let { registerSuccess, location } = props;
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const {
    addMessage,
    user,
    setLoginVisible,
    setPopupVisible,
    loginVisible,
    regVisible,
    popupVisible,
  } = useContext(globalContext);
  const [delaying, setDelaying] = useState(false);
  let params = new URLSearchParams(location.search);
  let email = params.get("email");
  let resetCode = params.get("code");

  useEffect(() => {
    if (email && resetCode) {
      axios
        .post("/checkResetCode", { email, resetCode })
        .then(() => setLoading(false))
        .catch((err) => parseErrors(err).then((msg) => addMessage(msg, "err")));
    } else if (user) {
      setPopupVisible(true);
      setLoading(false);
    } else {
      setLoginVisible(true);
      setLoading(false);
    }
  }, []);

  if (loading) return <></>;
  return (
    <>
      <Col
        className="mainContent"
        style={{
          height: "95vh",
          width: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "6vh",
          backgroundColor: `rgba(0,0,0,${
            loginVisible || regVisible || popupVisible ? ".7" : "0"
          })`,
        }}
      >
        {/* <div className="innerMainContent"> */}
        {!user && !email ? (
          <>
            {!delaying ? <Login /> : <></>}
            <Register registerSuccess={registerSuccess} />
          </>
        ) : (
          <></>
        )}
        {user && !email ? <Popup /> : <></>}
        {!user && email ? (
          <ResetPassword email={email} resetCode={resetCode} />
        ) : (
          <></>
        )}
        {/* </div> */}
      </Col>
    </>
  );
};

export default Home;
